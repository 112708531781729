import React from 'react';
import PropTypes from 'prop-types';

const TabPanels = ({ activeIndex, children: childrenProp }) => {
  const children = React.Children.map(childrenProp, (child, index) => {
    return React.cloneElement(child, {
      activeIndex: activeIndex,
      index: index,
    });
  });

  return children;
};

TabPanels.propTypes = {
  activeIndex: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.object),
};

export default TabPanels;

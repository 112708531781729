import React, { useEffect, useState } from 'react';
import Table from '../../../../components/table';
import { useCandidatesTableDataService } from '../../../../services/candidates-table-data-service';
import { usePaginationService } from '../../../../services/pagination-service';
import { useAuth } from '../../../../providers/auth-provider';
import { generateCandidateTableDataColumns } from '../../../../utils/candidate-table-data-helper';

const CandidatesTable = ({ filterType }) => {
  const { meta } = useAuth();
  const [hasFetchError, setHasFetchError] = useState(false);
  const [candidates, setCandidates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getAndSetCandidatesTableData } = useCandidatesTableDataService();
  const { getDefaultPaginationSettings } = usePaginationService();
  const [paginationData, setPaginationData] = useState(
    getDefaultPaginationSettings()
  );
  const SEARCH_QUERY = undefined;

  const tableStateCallbacks = {
    setCandidates: setCandidates,
    setHasFetchError: setHasFetchError,
    setIsLoading: setIsLoading,
    setPaginationData: setPaginationData,
  };

  const onPageChange = paginationData => {
    getAndSetCandidatesTableData(
      filterType,
      tableStateCallbacks,
      paginationData,
      isLoading,
      SEARCH_QUERY
    );
  };

  useEffect(() => {
    getAndSetCandidatesTableData(
      filterType,
      tableStateCallbacks,
      paginationData,
      isLoading,
      SEARCH_QUERY
    );
  }, []);

  return (
    <Table
      columns={generateCandidateTableDataColumns(
        meta.getCandidateDataFromBullhorn
      )}
      isLoading={isLoading}
      rowData={candidates}
      tableClass={`is-fullwidth`}
      hasFetchError={hasFetchError}
      paginationData={paginationData}
      pageChangeCallback={onPageChange}
    />
  );
};

export default CandidatesTable;

import React, { useState, useEffect } from 'react';
import styles from './list-of-skills-checklists.module.scss';
import { useSkillsChecklistsApi } from '../../../../../services/skills-checklist-service';
import LoadingSpinner from '../../../../../components/loading-spinner';
import ApiErrorMessage from '../../../../../components/error-messages/api-error-message';
import { errorMessages } from '../../../../../constants/error-messages';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CHECKLIST_STATUSES } from '../../../../../constants/checklist-statuses';
import {
  faCheckCircle,
  faExclamationCircle,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';

const ListOfSkillsChecklists = ({ profileId }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [hasApiError, setHasApiError] = useState(false);
  const { getSkillsChecklists } = useSkillsChecklistsApi();

  const [checklists, setChecklists] = useState([]);

  useEffect(() => {
    getSkillsChecklists(profileId)
      .then(({ data }) => {
        setChecklists(data);
      })
      .catch(() => {
        setHasApiError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  const getChecklistIcon = status => {
    switch (status) {
      case CHECKLIST_STATUSES.INCOMPLETE:
      case CHECKLIST_STATUSES.NOT_STARTED:
        return faTimesCircle;
      case CHECKLIST_STATUSES.EXPIRED:
      case CHECKLIST_STATUSES.EXPIRING_SOON:
        return faExclamationCircle;
      default:
        return faCheckCircle;
    }
  };

  const getChecklistIconClassName = status => {
    switch (status) {
      case CHECKLIST_STATUSES.INCOMPLETE:
      case CHECKLIST_STATUSES.NOT_STARTED:
        return styles.incompleteIcon;
      case CHECKLIST_STATUSES.EXPIRED:
      case CHECKLIST_STATUSES.EXPIRING_SOON:
        return styles.expiredIcon;
      default:
        return styles.completeIcon;
    }
  };

  const getChecklistStatusLabel = status => {
    switch (status) {
      case CHECKLIST_STATUSES.INCOMPLETE:
      case CHECKLIST_STATUSES.NOT_STARTED:
        return <em>(Incomplete)</em>;
      case CHECKLIST_STATUSES.EXPIRED:
        return <em>(Expired)</em>;
      case CHECKLIST_STATUSES.EXPIRING_SOON:
        return <em>(Expiring Soon)</em>;
      default:
        return null;
    }
  };

  return (
    <div className="content">
      {hasApiError ? (
        <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_FETCH} />
      ) : isLoading ? (
        <LoadingSpinner isLoading={isLoading} />
      ) : (
        <ul>
          {checklists.map((checklist, index) => (
            <li className={styles.checklistItem} key={index}>
              {
                <FontAwesomeIcon
                  icon={getChecklistIcon(checklist.status)}
                  className={getChecklistIconClassName(checklist.status)}
                />
              }{' '}
              {checklist.name} {getChecklistStatusLabel(checklist.status)}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ListOfSkillsChecklists;

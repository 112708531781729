import PropTypes from 'prop-types';

const TabPanel = ({ activeIndex, children, index }) => {
  const isActive = activeIndex === index;

  return isActive ? children : null;
};

TabPanel.propTypes = {
  activeIndex: PropTypes.number,
  children: PropTypes.object,
  index: PropTypes.number,
};

export default TabPanel;

import React, { useEffect, useState } from 'react';
import Layout from '../../../../components/layout';
import { authRoles } from '../../../../constants/auth-roles';
import { useAuth } from '../../../../providers/auth-provider';
import SimplifiedHeader from '../../../../components/simplified-header';
import styles from './index.module.scss';
import sharedStyles from '../../shared/index.module.scss';
import { navigate } from '@reach/router';
import SearchBar from '../../../../components/search-bar';
import SimplifiedFooter from '../../../../components/simplified-footer';
import { getParamFromUrl } from '../../../../utils/url-helpers';
import { useMetaApi } from '../../../../services/meta-service';
import DashboardRoutes from './routes';
/* eslint-disable jsx-a11y/no-autofocus */

const Dashboard = ({ location }) => {
  const { getFullName, hasRole } = useAuth();
  const { firstName, lastName } = getFullName();
  const [searchQuery, setSearchQuery] = useState('');
  const { isDashboardVersionTwo } = useMetaApi();
  const isV2 = isDashboardVersionTwo();

  const DASHBOARD_BASE_PATH = isV2
    ? '/recruiter/dashboard/v2/'
    : '/recruiter/dashboard/';
  const isOnSearchPage = location.pathname.includes('/candidates/search');
  const isOnSubmittalCreationPage =
    location.pathname.includes('/create-submittals');
  const isOnMainDashboardPage =
    location.pathname === DASHBOARD_BASE_PATH ||
    location.pathname === `${DASHBOARD_BASE_PATH}in-progress/` ||
    location.pathname === `${DASHBOARD_BASE_PATH}complete/`;

  const searchRef = React.createRef();

  const handleSearchSubmission = text => {
    const hasActiveSearch = !!text;
    if (hasActiveSearch) {
      navigate(`${DASHBOARD_BASE_PATH}candidates/search/?query=${text}`);
    } else {
      navigate(DASHBOARD_BASE_PATH);
    }
  };

  useEffect(() => {
    if (isOnSearchPage) {
      setSearchQuery(getParamFromUrl('query'));
    } else if (isOnMainDashboardPage) {
      setSearchQuery('');
    }
  }, [location]);

  useEffect(() => {
    if (searchRef !== null && isOnSearchPage) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  const getDashboardNameByRole = () => {
    if (hasRole(authRoles.RECRUITER)) return 'Recruiter';
    else if (hasRole(authRoles.ADMIN_ASSISTANT)) return 'Admin';

    return '';
  };

  return (
    <Layout
      role={authRoles.RECRUITER}
      headerComponent={SimplifiedHeader}
      footerComponent={SimplifiedFooter}
      showHeader={!isV2}
      showFooter={!isV2}
      hideContactBanner
    >
      {!isV2 && !isOnSubmittalCreationPage && (
        <>
          <div className={sharedStyles.divider} />
          <h1 className={`title is-2 ${styles.nameText} has-text-centered`}>
            {getDashboardNameByRole()} Dashboard - {firstName} {lastName}
          </h1>
          <div className={`${styles.searchBarContainer}`}>
            <SearchBar
              ref={searchRef}
              autoFocus={true}
              onSearch={handleSearchSubmission}
              searchQuery={searchQuery}
              placeholderText="Search Candidates"
            />
          </div>
        </>
      )}
      <DashboardRoutes basepath={DASHBOARD_BASE_PATH} />
    </Layout>
  );
};

export default Dashboard;

import React from 'react';
import styles from './index.module.scss';

const SubmittalOptionsSelection = ({
  sectionTitle,
  instructions,
  items,
  getTagElementForStatus,
  handleOnChange,
  noMembersInCollectionMessage,
  getCompactTag,
}) => {
  const hasItems = items && items.length > 0;
  return (
    <div className={`columns is-vcentered`}>
      <div className={`column ${styles.checklistsSection}`}>
        <h5 className={`is-size-5 has-text-weight-semibold`}>{sectionTitle}</h5>
        <p className={styles.checklistsLabel}>{instructions}</p>
        {hasItems ? (
          items.map(item => (
            <label
              key={item.name}
              className={`label ${styles.checkboxItem}  ${item.labelClassName}`}
            >
              <input
                checked={item.isDisplayed}
                type="checkbox"
                onChange={() => handleOnChange(item.id)}
                disabled={item.isCheckBoxDisabled}
                className={`${styles.checklistCheckbox}`}
              />{' '}
              {item.name}
              {item.location && (
                <span className={`is-size-6 ${styles.location}`}>
                  {`- ${item.location}`}
                </span>
              )}
              {item.validThruDateSpan && item.validThruDateSpan}
              {getTagElementForStatus(item.status)}
              {getCompactTag(item.isCompact)}
            </label>
          ))
        ) : (
          <p className="is-italic">
            {noMembersInCollectionMessage ??
              'There are no items in this section for selection'}
          </p>
        )}
      </div>
    </div>
  );
};

export default SubmittalOptionsSelection;

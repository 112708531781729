import React from 'react';
import PropTypes from 'prop-types';

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

const Tab = ({ index, isActive, label, onClick }) => (
  <li className={isActive ? 'is-active' : ''}>
    <a onClick={() => onClick(index)}>{label}</a>
  </li>
);

Tab.propTypes = {
  index: PropTypes.number,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

export default Tab;

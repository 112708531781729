import React, { useEffect, useState } from 'react';
import { useCandidateDetailsPageApi } from '../../../../../services/candidate-details-page-service';
import { Link, navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../../../../components/loading-spinner';
import CompletionStatusField from '../../../../../components/profile-completion-status-field';
import { profileCompletionStatusIds } from '../../../../../constants/profile-completion-status-ids';
import { profileSectionsInfo } from '../../../../../constants/profile-sections-info';
import styles from './index.module.scss';

import ApiErrorMessage from '../../../../../components/error-messages/api-error-message';
import { errorMessages } from '../../../../../constants/error-messages';
import ListOfSkillsChecklists from './list-of-skills-checklists';

const CandidateDetails = ({ bullhornId }) => {
  const { getCandidateWithChecklistExemptionStatus } =
    useCandidateDetailsPageApi();
  const [candidate, setCandidate] = useState({});
  const [hasFetchError, setHasFetchError] = useState(false);

  const isLoading = Object.keys(candidate).length === 0 && !hasFetchError;

  useEffect(() => {
    getCandidateWithChecklistExemptionStatus(bullhornId)
      .then(data => {
        setCandidate(data);
      })
      .catch(() => {
        setHasFetchError(true);
      });
  }, []);

  const isSectionComplete = statusId => {
    return statusId === profileCompletionStatusIds.COMPLETE;
  };

  return (
    <div className={styles.contentContainer}>
      {isLoading ? (
        <LoadingSpinner isLoading={isLoading} />
      ) : hasFetchError ? (
        <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_FETCH} />
      ) : (
        <>
          <div className={styles.navButtons}>
            <button
              className={`button is-primary is-outlined is-square ${styles.backButton}`}
              onClick={() => navigate(-1)}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>
            </button>
            {candidate.id && (
              <Link
                className={`button is-info is-square`}
                to={`/recruiter/dashboard/candidate-submittal-details/${candidate.id}`}
              >
                Submittal Details
              </Link>
            )}
          </div>
          <div className={styles.headerSection}>
            <div>
              <h3
                className={`subtitle is-2 has-text-info ${styles.subtitleText}`}
              >
                {candidate.name} - {candidate.primarySpecialty}
              </h3>
              <p className={`is-size-5`}>{candidate.email}</p>
            </div>
          </div>
          <div className={styles.fieldArrayContainer}>
            {candidate.sections.map((section, index) => (
              <div key={index}>
                <hr className={`${styles.formDivider} `} />
                <CompletionStatusField
                  key={`section-${section.name}-${index}`}
                  isComplete={isSectionComplete(section.statusId)}
                  name={section.name}
                  isOptional={section.isOptional}
                  missingFields={section.missingFields}
                />
                {candidate.id &&
                  section.id === profileSectionsInfo.skillsChecklists.id && (
                    <ListOfSkillsChecklists profileId={candidate.id} />
                  )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default CandidateDetails;

import { useAuthenticatedRequest } from '../hooks/api-hooks';
import { profileOverallCompletionStatuses } from '../constants/profile-overall-completion-status';
import { profileSectionsInfo } from '../constants/profile-sections-info';
import { buildCandidateSearchEndpoint } from '../utils/url-helpers';
import { getFormattedDate } from '../utils/format-helpers';

export function useBullhornCandidatesApi() {
  const { get, post } = useAuthenticatedRequest();

  const BULLHORN_CANDIDATES_CONTROLLER_ENDPOINT =
    '/api/bullhorn-candidate-search';

  const formatCandidate = candidate => {
    const formatted = {
      name: `${candidate.firstName} ${candidate.lastName}`,
      ...candidate,
    };
    formatted.lastUpdated = getFormattedDate(candidate.lastUpdated);
    formatted.status = getCandidateStatus(candidate);
    if (candidate.completionStatus)
      formatted.sections = formatSections(candidate.completionStatus.sections);

    return formatted;
  };

  const getCandidateStatus = candidate => {
    let status;
    if (candidate.isApplicationDataComplete) {
      status = candidate.isChecklistSectionComplete
        ? profileOverallCompletionStatuses.APP_COMPLETE_CHECKLIST_COMPLETE
        : profileOverallCompletionStatuses.APP_COMPLETE_CHECKLIST_INCOMPLETE;
    } else {
      status = candidate.isChecklistSectionComplete
        ? profileOverallCompletionStatuses.APP_INCOMPLETE_CHECKLIST_COMPLETE
        : profileOverallCompletionStatuses.APP_INCOMPLETE_CHECKLIST_INCOMPLETE;
    }

    return status;
  };

  const formatSections = statusList => {
    return Object.keys(profileSectionsInfo).map(sectionName =>
      createObjectForFormatSectionsMap(
        statusList,
        sectionName,
        profileSectionsInfo
      )
    );
  };

  const createObjectForFormatSectionsMap = (
    statusList,
    sectionName,
    profileSectionsInfo
  ) => {
    const section = statusList.find(
      section => section.sectionName === sectionName
    );
    return {
      ...profileSectionsInfo[sectionName],
      statusId: section.status,
      isOptional: false,
      missingFields: section.missingFields,
    };
  };

  return {
    getCandidatesFromBullhorn: function (
      recruiterProfileId,
      filterTypeId,
      limit,
      offset,
      searchQuery
    ) {
      return new Promise((resolve, reject) => {
        post(
          buildCandidateSearchEndpoint(
            BULLHORN_CANDIDATES_CONTROLLER_ENDPOINT,
            recruiterProfileId,
            filterTypeId,
            limit,
            offset,
            searchQuery
          )
        )
          .then(({ data }) => {
            const formattedCandidates = data.candidates.map(c =>
              formatCandidate(c)
            );
            data.candidates = formattedCandidates;
            resolve(data);
          })
          .catch(err => reject(err));
      });
    },

    getCandidate: function (bullhornId) {
      return new Promise((resolve, reject) => {
        get(`${BULLHORN_CANDIDATES_CONTROLLER_ENDPOINT}/${bullhornId}`)
          .then(({ data }) => {
            const candidate = formatCandidate(data);
            resolve(candidate);
          })
          .catch(err => reject(err));
      });
    },
  };
}

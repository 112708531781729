import React, { createContext, useState } from 'react';

export const RecruitersContext = createContext();

const RecruitersProvider = ({ children }) => {
  const [candidateIds, setCandidateIds] = useState([]);
  return (
    <RecruitersContext.Provider value={{ candidateIds, setCandidateIds }}>
      {children}
    </RecruitersContext.Provider>
  );
};

export default RecruitersProvider;

import React from 'react';
import LoadingSpinner from '../../../../../components/loading-spinner';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from '@mui/material';
import styles from './index.module.scss';

const DistanceToFacilityColumn = ({
  haveSubmissionDistancesLoaded,
  distanceData,
}) => {
  return (
    <>
      {haveSubmissionDistancesLoaded ? (
        <LoadingSpinner isLoading isResponsive />
      ) : (
        <div className="has-text-right">
          {distanceData?.distanceInMiles ? (
            distanceData.distanceInMiles
          ) : (
            <>
              --
              {distanceData?.hasError && (
                <Tooltip
                  arrow
                  placement="top"
                  leaveDelay={100}
                  title={'Invalid facility address'}
                >
                  <span className={styles.milesNotificationIcon}>
                    <FontAwesomeIcon icon={faExclamationCircle} />
                  </span>
                </Tooltip>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default DistanceToFacilityColumn;

DistanceToFacilityColumn.propTypes = {
  haveSubmissionDistancesLoaded: PropTypes.bool.isRequired,
  distanceData: PropTypes.shape({
    submissionId: PropTypes.number.isRequired,
    distanceInMiles: PropTypes.number,
    error: PropTypes.string,
    hasError: PropTypes.bool.isRequired,
  }),
};

import { useChecklistExemptionApi } from './checklist-exemption-service';
import { profileSectionsInfo } from '../constants/profile-sections-info';
import { useBullhornCandidatesApi } from './bullhorn-candidates-service';

const setChecklistSectionOptionalIfExempted = (candidate, isExempt) => {
  if (isExempt) {
    candidate.sections.find(
      section => section.id === profileSectionsInfo.skillsChecklists.id
    ).isOptional = true;
  }
};

export function useCandidateDetailsPageApi() {
  const { getCandidate } = useBullhornCandidatesApi();
  const { isExemptFromSkillsChecklists } = useChecklistExemptionApi();

  return {
    getCandidateWithChecklistExemptionStatus: function (bullhornId) {
      return new Promise((resolve, reject) => {
        getCandidate(bullhornId)
          .then(candidateValues => {
            const candidate = candidateValues;
            if (candidate.id) {
              isExemptFromSkillsChecklists(candidate.id).then(
                exemptionValues => {
                  const isExempt = exemptionValues.data;
                  setChecklistSectionOptionalIfExempted(candidate, isExempt);
                  resolve(candidate);
                }
              );
            } else {
              resolve(candidate);
            }
          })
          .catch(err => reject(err));
      });
    },
  };
}

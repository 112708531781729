import React from 'react';
import PropTypes from 'prop-types';
import SyncStatusDataRow from './sync-status-data-row';
import { useCandidatePiiApi } from '../../../../../../services/candidate-pii-service';
import { piiCrmSyncStatuses } from '../../../../../../constants/pii-crm-sync-statuses';
const PiiSyncSection = ({
  candidateProfileId,
  piiData,
  updateCallbackFunction,
}) => {
  const { forceWriteDobToCrm, forceWriteSsnToCrm } = useCandidatePiiApi();

  return (
    <>
      <SyncStatusDataRow
        statusTypeId={piiData.dob}
        fieldHeadingName="Date Of Birth"
        fieldTextName="date of birth"
        fieldShortName="DOB"
        candidateProfileId={candidateProfileId}
        valueMask="**/**/****"
        syncFunction={forceWriteDobToCrm}
        fieldUpdateCallback={() => {
          const updated = piiData;
          updated.dob = piiCrmSyncStatuses.IN_SYNC;
          updateCallbackFunction(updated);
        }}
      />
      <SyncStatusDataRow
        statusTypeId={piiData.ssn}
        fieldHeadingName="Social Security Number"
        fieldTextName="social security number"
        fieldShortName="SSN"
        candidateProfileId={candidateProfileId}
        valueMask="***_**_****"
        syncFunction={forceWriteSsnToCrm}
        fieldUpdateCallback={() => {
          const updated = piiData;
          updated.ssn = piiCrmSyncStatuses.IN_SYNC;
          updateCallbackFunction(updated);
        }}
      />
    </>
  );
};

PiiSyncSection.propTypes = {
  candidateProfileId: PropTypes.number.isRequired,
  updateCallbackFunction: PropTypes.func.isRequired,
  piiData: PropTypes.shape({
    dob: PropTypes.number.isRequired,
    ssn: PropTypes.number.isRequired,
  }).isRequired,
};
export default PiiSyncSection;

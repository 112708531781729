import React from 'react';
import styles from './index.module.scss';

const NameCard = ({ name, initials, primarySpecialty }) => {
  return (
    <>
      <div className={`${styles.nameCardBox}`}>
        <div className={`${styles.initialsContainer}`}>
          <div className={`${styles.nameCardIcon}`}>{initials}</div>
        </div>
        <div className={`${styles.nameAndSpecialtyContainer}`}>
          <div className={`has-text-weight-bold`}>{name}</div>
          <div>{primarySpecialty}</div>
        </div>
      </div>
    </>
  );
};

export default NameCard;

import * as Yup from 'yup';

const MAX_RECRUITER_COMMENT_CHAR_COUNT = 3000;
const COMMENT_EXCEEDS_LENGTH_LIMIT = `Please limit comments to less than ${MAX_RECRUITER_COMMENT_CHAR_COUNT} characters.`;
const COMMENT_IS_REQUIRED = 'Candidate Highlight is required.';

const validationSchema = selectedJobSubmittalIds => {
  function isSelected(submittalId) {
    return selectedJobSubmittalIds.includes(submittalId);
  }

  const schema = Yup.object({
    submittals: Yup.array().of(
      Yup.object({
        id: Yup.number(),
        isBranded: Yup.string().when('id', {
          is: submittalId => isSelected(submittalId),
          then: Yup.string().required('Please select a PDF style'),
        }),
      })
    ),
    recruiterComments: Yup.string()
      .required(COMMENT_IS_REQUIRED)
      .max(MAX_RECRUITER_COMMENT_CHAR_COUNT, COMMENT_EXCEEDS_LENGTH_LIMIT),
  });

  return schema;
};

export default validationSchema;

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import styles from './index.module.scss';
import LoadingSpinner from '../../../../../../components/loading-spinner';
import PropTypes from 'prop-types';

export const SubmittalUploadInProgress = () => {
  return (
    <div className={`${styles.marginBottom40}`}>
      <LoadingSpinner isLoading={true} />
      <p className="is-size-6 has-text-centered">Creating Submittal Packets</p>
      <p className="is-size-7 has-text-centered">
        This process could take up to 2 minutes
      </p>
    </div>
  );
};

export const SubmittalUploadComplete = ({ isSuccess, errorMessage }) => {
  errorMessage = errorMessage ?? 'Unable to create submittal packets';
  return (
    <div className={`${styles.marginBottom40}`}>
      <div
        className={`${styles.successIconContainer} ${
          isSuccess ? styles.isStatusSuccess : styles.isStatusFailure
        } is-size-3`}
      >
        {isSuccess ? (
          <FontAwesomeIcon icon={faCheckCircle} />
        ) : (
          <FontAwesomeIcon icon={faExclamationCircle} />
        )}
      </div>
      <p className={`is-size-6 has-text-centered ${styles.paddingLeftRight15}`}>
        {isSuccess
          ? 'Submittal packets successfully created and uploaded to Bullhorn'
          : errorMessage}
      </p>
    </div>
  );
};

SubmittalUploadComplete.propTypes = {
  isSuccess: PropTypes.bool.isRequired,
};

export const SubmittalUploadFailedDocumentsTable = ({ failedSubmissions }) => {
  return (
    <div className={`${styles.failedUploadsTableContainer}`}>
      <h2
        className={`is-size-6 has-text-left ${styles.failedSubmissionMessage}`}
      >
        The following errors occurred
      </h2>
      <div className={`${styles.failedUploadsTable}`}>
        <FailedSubmissionTableHeaders />
        {failedSubmissions?.map(failedSubmission => {
          return (
            <FailedSubmissionRow
              key={failedSubmission.jobSubmittalId}
              failedSubmission={failedSubmission}
            />
          );
        })}
      </div>
    </div>
  );
};

SubmittalUploadFailedDocumentsTable.propTypes = {
  failedSubmissions: PropTypes.array.isRequired,
};

const FailedSubmissionTableHeaders = () => {
  return (
    <div className={`${styles.failedSubmissionBackground}`}>
      <div
        className={`${styles.failedUploadsTableGrid} has-text-weight-bold has-text-left`}
      >
        <div>Match ID</div>
        <div>Status</div>
      </div>
    </div>
  );
};

const FailedSubmissionRow = ({ failedSubmission }) => {
  const pdfName = failedSubmission.fileDownloadName;
  const downloadUrl = failedSubmission.fileUrl;
  return (
    <div className={`${styles.failedSubmissionBackground}`}>
      <div className={`${styles.failedUploadsTableGrid}`}>
        <div className={`${styles.firstColumn}`}>
          {failedSubmission.jobSubmittalId}
        </div>
        <div className={`${styles.secondColumn}`}>
          <div className={`${styles.failureIconContainer}`}>
            <FontAwesomeIcon icon={faExclamationCircle} />
          </div>
          <div className={`${styles.nestedSecondColumn}`}>
            <div>{failedSubmission.errorMessage}</div>
            {failedSubmission.fileContent && (
              <a
                className={`${styles.downloadLink}`}
                href={downloadUrl}
                download={pdfName}
              >
                Download submittal packet
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';
import {
  faCheckCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { piiCrmSyncStatuses } from '../../../../../../../constants/pii-crm-sync-statuses';
import Modal from '../../../../../../../components/modal';
import PopupNotification from '../../../../../../../components/popup-notification';

const SyncStatusDataRow = ({
  statusTypeId,
  fieldHeadingName,
  fieldTextName,
  fieldShortName,
  valueMask,
  candidateProfileId,
  syncFunction,
  fieldUpdateCallback,
}) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPopupNotification, setShowPopupNotification] = useState(false);
  const [hasApiError, setHasApiError] = useState(false);
  const COMPONENT_STATES = [
    {
      id: piiCrmSyncStatuses.MISSING_FROM_WEBSITE,
      icon: faExclamationTriangle,
      iconClass: styles.error,
      textClass: styles.error,
      message: `Not provided by candidate yet.`,
      disableButton: true,
    },
    {
      id: piiCrmSyncStatuses.IN_SYNC,
      icon: faCheckCircle,
      iconClass: styles.success,
      textClass: '',
      message: valueMask,
      disableButton: true,
    },
    {
      id: piiCrmSyncStatuses.OUT_OF_SYNC,
      icon: faExclamationTriangle,
      iconClass: styles.error,
      textClass: styles.error,
      message: `Candidate has changed their ${fieldTextName}.  To accept this change, please sync the value to Bullhorn.  To decline, reach out to your manager for assistance.`,
      disableButton: false,
    },
    {
      id: piiCrmSyncStatuses.NOT_LINKED,
      icon: faExclamationTriangle,
      iconClass: styles.error,
      textClass: styles.error,
      message:
        'Candidate is not linked to an account in Bullhorn. Please reach out to your manager for assistance.',
      disableButton: true,
    },
  ];
  const currentComponentState = COMPONENT_STATES.find(
    stateObj => stateObj.id === statusTypeId
  );

  const handleSubmit = () => {
    if (hasApiError) setHasApiError(false);

    setIsSubmitting(true);
    syncFunction(candidateProfileId)
      .then(() => {
        fieldUpdateCallback();
      })
      .catch(() => {
        setHasApiError(true);
      })
      .finally(() => {
        setIsSubmitting(false);
        setShowConfirmationModal(false);
        setShowPopupNotification(true);
      });
  };

  const notificationMessage = hasApiError
    ? `Failed to update ${fieldTextName}.`
    : `Successfully updated ${fieldTextName}.`;

  return (
    <>
      <div className={`${styles.dataRow} `}>
        <div className={styles.dataField}>
          <h6 className={`is-size-5 has-text-weight-semibold`}>
            {fieldHeadingName}
          </h6>
          <span
            className={`${currentComponentState.textClass} ${styles.messageContainer}`}
          >
            <FontAwesomeIcon
              icon={currentComponentState.icon}
              className={`fa-x ${currentComponentState.iconClass} ${styles.syncIcon}`}
            />
            <p>{currentComponentState.message}</p>
          </span>
        </div>
        <button
          type="button"
          className={`button is-info has-text-right`}
          disabled={currentComponentState.disableButton}
          onClick={() => setShowConfirmationModal(true)}
        >
          Update {fieldShortName} in Bullhorn
        </button>
      </div>
      <Modal
        onConfirm={handleSubmit}
        onCancel={() => setShowConfirmationModal(false)}
        isCentered
        isOpen={showConfirmationModal}
        isSubmitting={isSubmitting}
        title={`Update ${fieldTextName} in Bullhorn`}
        message={`Are you sure you want to overwrite Bullhorn with the updated ${fieldTextName}? This action cannot be undone.`}
        confirmButtonText={`Overwrite ${fieldTextName}`}
        messageModifierClass="is-info"
        confirmButtonModifierClass={'is-info'}
      />
      {showPopupNotification && (
        <PopupNotification
          message={notificationMessage}
          onClose={() => setShowPopupNotification(false)}
          notificationStyleClass={hasApiError ? 'is-danger' : 'is-success'}
        />
      )}
    </>
  );
};

SyncStatusDataRow.propTypes = {
  statusTypeId: PropTypes.number.isRequired,
  fieldHeadingName: PropTypes.string.isRequired,
  fieldTextName: PropTypes.string.isRequired,
  fieldShortName: PropTypes.string.isRequired,
  valueMask: PropTypes.string.isRequired,
  candidateProfileId: PropTypes.number.isRequired,
  syncFunction: PropTypes.func.isRequired,
  fieldUpdateCallback: PropTypes.func.isRequired,
};

export default SyncStatusDataRow;

import React, { useEffect, useState } from 'react';
import { useCandidateSubmittalDataApi } from '../../../../../services/candidate-submittal-data-service';
import styles from './index.module.scss';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { errorMessages } from '../../../../../constants/error-messages';
import LoadingSpinner from '../../../../../components/loading-spinner';
import { authRoles } from '../../../../../constants/auth-roles';
import { useAuth } from '../../../../../providers/auth-provider';
import ApiErrorMessage from '../../../../../components/error-messages/api-error-message';
import Datafield from '../../datafield';
import SupplementalDocsSection from '../../supplemental-docs-section';
import GeneratePdfsButton from '../../../../common/components/generate-pdfs-button';
import SubmittalOptions from './submittal-options';
import SubmittalCreationSection from '../../submittal-creation-section';
import PiiSyncSection from './pii-sync-section';
import { useMetaApi } from '../../../../../services/meta-service';

const CandidateSubmittalDetails = ({ location, candidateProfileId }) => {
  const { isDashboardVersionTwo } = useMetaApi();
  const isV1 = isDashboardVersionTwo() === false;
  const NOT_AVAILABLE = 'N/A';
  const { getCandidateSubmittalData } = useCandidateSubmittalDataApi();
  const { hasRole, meta } = useAuth();
  const [candidate, setCandidate] = useState({});
  const [hasFetchError, setHasFetchError] = useState(false);
  const [selectedCredentialIds, setSelectedCredentialIds] = useState([]);
  const [selectedChecklistIds, setSelectedChecklistIds] = useState([]);
  const isLoading = Object.keys(candidate).length === 0 && !hasFetchError;
  const isBullhornActive = meta.shouldUseBullhorn;
  const shouldHidePiiSection = meta.getCandidateDataFromBullhorn;

  useEffect(() => {
    getCandidateSubmittalData(candidateProfileId)
      .then(data => {
        setCandidate(data);
      })
      .catch(() => setHasFetchError(true));
  }, []);

  const getAddress = () => {
    const contact = candidate.contactInfo;
    const cityState = getCityAndStateFormattedIfAvailable(
      contact.city,
      contact.streetAddressState
    );

    const values = [contact.streetAddress, cityState, contact.zipCode];
    const usableValues = values.filter(value => value?.length);
    const formatted = usableValues.length
      ? usableValues.join(' ')
      : NOT_AVAILABLE;

    return formatted;
  };

  const getCityAndStateFormattedIfAvailable = (city, state) => {
    if (city && state) return `${city}, ${state}`;
    return city || state || null;
  };

  const getNameAndSpecialty = () => {
    const contact = candidate?.contactInfo;

    let formattedNameAndSpecialty = '';
    if (contact === null) return formattedNameAndSpecialty;
    if (contact.firstName) formattedNameAndSpecialty += `${contact.firstName} `;
    if (contact.middleName)
      formattedNameAndSpecialty += `${contact.middleName} `;
    if (contact.lastName) formattedNameAndSpecialty += `${contact.lastName} `;
    if (candidate?.primarySpecialtyName)
      formattedNameAndSpecialty += ` - ${candidate.primarySpecialtyName}`;

    return formattedNameAndSpecialty;
  };

  const piiSyncUpdateCallback = updatedPiiSyncValues => {
    const updatedCandidate = { ...candidate };
    updatedCandidate.pii.syncStatus = updatedPiiSyncValues;
    setCandidate(updatedCandidate);
  };

  return (
    <div className={styles.contentContainer}>
      {isLoading ? (
        <LoadingSpinner isLoading={isLoading} />
      ) : hasFetchError ? (
        <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_FETCH} />
      ) : (
        <>
          {isV1 && (
            <div className={styles.navButtons}>
              <button
                className={`button is-primary is-outlined is-square ${styles.backButton}`}
                onClick={() => navigate(-1)}
              >
                <span className="icon">
                  <FontAwesomeIcon icon={faChevronLeft} />
                </span>
              </button>
            </div>
          )}
          <div className={styles.headerSection}>
            <div>
              <h3
                className={`subtitle is-2 has-text-info ${styles.subtitleText}`}
              >
                {getNameAndSpecialty()}
              </h3>
              <p className={`is-size-5`}>{candidate.contactInfo.email}</p>
            </div>
            {isV1 &&
              candidate.isMarkedCompleteInCrm &&
              candidate.isChecklistSectionComplete && (
                <div className={`${styles.viewButtonContainer}`}>
                  <GeneratePdfsButton profileId={candidate.profileId} />
                </div>
              )}
          </div>
          <hr className={styles.divider} />
          <Datafield
            heading={`Phone Number`}
            text={candidate.contactInfo.phoneNumber}
            defaultText={NOT_AVAILABLE}
          />
          <Datafield
            heading={`Address`}
            text={getAddress()}
            defaultText={NOT_AVAILABLE}
          />
          {candidate.contactInfo.preferredInterviewTime && (
            <Datafield
              heading={`Preferred Interview time`}
              text={candidate.contactInfo.preferredInterviewTime}
              defaultText={NOT_AVAILABLE}
            />
          )}
          {shouldHidePiiSection === false && (
            <>
              <hr className={styles.divider} />
              {hasRole(authRoles.ADMIN_ASSISTANT) ? (
                <div className={`${styles.piiContainer} ${styles.flexColumns}`}>
                  <PiiSyncSection
                    piiData={candidate.pii.syncStatus}
                    candidateProfileId={candidate.profileId}
                    updateCallbackFunction={piiSyncUpdateCallback}
                  />
                </div>
              ) : (
                <p>
                  You do not have permission to view this candidate's personal
                  information
                </p>
              )}
            </>
          )}
          <hr className={styles.divider} />
          <SupplementalDocsSection candidateProfileId={candidateProfileId} />
          <hr className={styles.divider} />
          <SubmittalOptions
            profileId={candidateProfileId}
            selectedCredentialIds={selectedCredentialIds}
            setSelectedCredentialIds={setSelectedCredentialIds}
            selectedChecklistIds={selectedChecklistIds}
            setSelectedChecklistIds={setSelectedChecklistIds}
          />
          <hr className={styles.divider} />
          <SubmittalCreationSection
            profileId={candidateProfileId}
            isBullhornEnv={isBullhornActive}
            isProfileComplete={candidate.isMarkedCompleteInCrm}
            selectedCredentialIds={selectedCredentialIds}
            selectedChecklistIds={selectedChecklistIds}
          />
        </>
      )}
    </div>
  );
};

export default CandidateSubmittalDetails;

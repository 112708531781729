import React from 'react';
import RecruiterAppWrapper from './recruiter-app-wrapper';
import { useAuth } from '../../providers/auth-provider';
import LoadingSpinner from '../../components/loading-spinner';

const RecruiterApp = ({ mounted }) => {
  const { hasMetaLoaded } = useAuth();

  if (mounted) {
    return hasMetaLoaded ? (
      <RecruiterAppWrapper />
    ) : (
      <LoadingSpinner isLoading={true} />
    );
  }
  return null;
};

export default RecruiterApp;

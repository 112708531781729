import React from 'react';
import ViewDocumentButton from '../../../../components/view-document-button';

const TableViewDocButton = ({
  rowData,
  dataKey,
  profileId,
  docTypeId,
  buttonText,
}) => {
  const docName = rowData[dataKey];
  return (
    <ViewDocumentButton
      profileId={profileId}
      documentTypeId={docTypeId}
      docName={docName}
      buttonText={buttonText}
    />
  );
};

export default TableViewDocButton;

import React, { useState, useEffect } from 'react';
import CandidatesTable from '../dashboard/candidates-table';
import { candidateFilterTypeIds } from '../../../../constants/recruiter-candidate-filter-ids';
import {
  Tab,
  TabPanel,
  TabPanels,
  Tabs,
  TabsArea,
} from '../../../../components/tabs';
import styles from './index.module.scss';
import { navigate } from '@reach/router';
import FeedbackBanner from '../feedback-banner';

const TableSection = ({ location }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const sections = {
    allCandidates: { path: '/recruiter/dashboard/', index: 0 },
    profilesInProgress: { path: '/recruiter/dashboard/in-progress/', index: 1 },
    completedProfiles: { path: '/recruiter/dashboard/complete/', index: 2 },
  };

  const handleTabClick = index => {
    navigate(getPathForTab(index));
    setActiveIndex(index);
  };

  const getPathForTab = index => {
    let path = sections.allCandidates.path;
    if (index === sections.profilesInProgress.index)
      path = sections.profilesInProgress.path;
    else if (index === sections.completedProfiles.index)
      path = sections.completedProfiles.path;
    return path;
  };

  useEffect(() => {
    if (location.pathname === sections.profilesInProgress.path)
      setActiveIndex(sections.profilesInProgress.index);
    else if (location.pathname === sections.completedProfiles.path)
      setActiveIndex(sections.completedProfiles.index);
    else setActiveIndex(sections.allCandidates.index);
  }, [location]);

  return (
    <>
      <div className={styles.contentContainer}>
        <TabsArea activeIndex={activeIndex} handleTabClick={handleTabClick}>
          <Tabs>
            <Tab
              label="All my candidates"
              path={sections.allCandidates.path}
            ></Tab>
            <Tab
              label="Profile in progress"
              path={sections.profilesInProgress.path}
            ></Tab>
            <Tab
              label="Profile complete"
              path={sections.completedProfiles.path}
            ></Tab>
          </Tabs>
          <TabPanels>
            <TabPanel>
              <CandidatesTable />
            </TabPanel>
            <TabPanel>
              <CandidatesTable
                filterType={candidateFilterTypeIds.PROFILE_IN_PROGRESS}
              />
            </TabPanel>
            <TabPanel>
              <CandidatesTable
                filterType={candidateFilterTypeIds.PROFILE_COMPLETE}
              />
            </TabPanel>
          </TabPanels>
        </TabsArea>
      </div>
      <FeedbackBanner />
    </>
  );
};

export default TableSection;

export function usePdfService() {
  const pdfNames = {
    DEFAULT_SUBMITTAL_PDF: 'Trustaff-Submittal',
    PDF_NAME_HEADER: 'file-name',
  };

  const getPdfNameFromHeaderOrDefault = reqHeaders => {
    let pdfName = reqHeaders[pdfNames.PDF_NAME_HEADER];
    return pdfName || pdfNames.DEFAULT_SUBMITTAL_PDF;
  };

  const downloadPdf = (pdfBlob, pdfName) => {
    const anchorHtmlElementName = 'a';
    let fileLink = document.createElement(anchorHtmlElementName);

    fileLink.href = URL.createObjectURL(pdfBlob);
    fileLink.download = pdfName;

    fileLink.click();
  };

  return {
    downloadNamedPdf: (res, finishedProcessingPdfResolveCb) => {
      const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
      const pdfName = getPdfNameFromHeaderOrDefault(res.headers);
      downloadPdf(pdfBlob, pdfName);
      finishedProcessingPdfResolveCb();
    },
  };
}

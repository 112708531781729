import React from 'react';
import { Link } from '@reach/router';

const EditLink = ({ rowData }) => {
  return rowData.shouldShowCandidateLinks ? (
    <Link
      to={rowData.candidateDetailsLinkPath}
      className="button is-small is-info"
    >
      Edit
    </Link>
  ) : null;
};

export default EditLink;

import React from 'react';
import styles from './index.module.scss';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

const FileUploadButton = ({
  handleFileUpload,
  buttonText,
  acceptedFileTypes,
  isUploading,
}) => {
  const LoadingButton = () => {
    return (
      <button
        className={`button is-info is-loading ${styles.loadingButton}`}
        aria-label="loading"
      />
    );
  };
  return isUploading ? (
    <LoadingButton />
  ) : (
    <label className="file-label">
      <input
        className={`file-input`}
        accept={acceptedFileTypes}
        type="file"
        onChange={e => {
          const file = e.target.files[0];
          if (file) {
            handleFileUpload(file);
          }
        }}
      />
      <span className={`file-cta`}>
        <span className="file-icon">
          <span className="icon ">
            <FontAwesomeIcon icon={faUpload} />
          </span>
        </span>
        <span className="file-label">
          <p>{buttonText}</p>
        </span>
      </span>
    </label>
  );
};

export default FileUploadButton;

FileUploadButton.propTypes = {
  handleFileUpload: PropTypes.func.isRequired,
  isUploading: PropTypes.bool.isRequired,
  acceptedFileTypes: PropTypes.string,
  buttonText: PropTypes.string,
};

FileUploadButton.defaultProps = {
  acceptedFileTypes: '*',
  buttonText: 'Upload File',
};

import React from 'react';

const TableDeleteDocButton = ({
  profileId,
  rowData,
  dataKey,
  handleDelete,
  docBeingDeleted,
}) => {
  const docName = rowData[dataKey];

  const showLoadingSpinner = docBeingDeleted === docName;
  return (
    <button
      className={`button is-small is-danger ${
        showLoadingSpinner ? 'is-loading' : ''
      }`}
      onClick={() => handleDelete(profileId, docName)}
    >
      Delete
    </button>
  );
};

export default TableDeleteDocButton;

import { useAuthenticatedRequest } from '../hooks/api-hooks';
import { getDateAsMMDD, getJsDateAsString } from '../utils/format-helpers';
import { usePdfService } from './pdf-service';
import { httpResponses } from '../constants/http-responses';

export function useCandidateSubmittalDataApi() {
  const { get, post } = useAuthenticatedRequest();
  const { downloadNamedPdf } = usePdfService();
  const CANDIDATE_SUBMITTAL_DATA_ENDPOINT = '/api/candidate-submittal-data';
  const BULLHORN_JOB_SUBMISSIONS_ENDPOINT = '/api/job-submissions';
  const BULLHORN_JOB_SUBMISSIONS_WITH_CANDITATE_ENDPOINT =
    '/api/job-submissions/with-candidate';
  const BULLHORN_SUBMITTAL_PDF_ENDPOINT = '/api/bullhorn-submittal-pdfs';
  const SUBMISSION_JOB_DISTANCE_ENDPOINT =
    '/api/job-submissions/get-distance-between-candidate-and-facility';
  const SUBMISSION_JOB_DISTANCES_ENDPOINT =
    '/api/job-submissions/get-distances-between-candidate-and-facilities';

  const getStateName = (id, states) => {
    return states.find(state => state.id === id)?.name;
  };

  const formatCandidate = (candidate, states) => {
    const formattedCandidate = candidate;

    const streetAddressState = getStateName(
      candidate.contactInfo.stateId,
      states
    );

    const formattedContact = {
      streetAddressState: streetAddressState,
      ...candidate.contactInfo,
    };
    formattedCandidate.contactInfo = formattedContact;

    if (formattedCandidate.pii?.values?.driversLicenseStateIssuedId) {
      const dlStateName = getStateName(
        formattedCandidate.pii.values.driversLicenseStateIssuedId,
        states
      );
      const piiValuesWithState = {
        driversLicenseState: dlStateName,
        ...candidate.pii.values,
      };
      formattedCandidate.pii.values = piiValuesWithState;
    }

    return formattedCandidate;
  };

  const formatResult = uploadResponse => {
    if (uploadResponse.failedSubmissions) {
      uploadResponse.failedSubmissions = uploadResponse.failedSubmissions.map(
        submission => ({
          ...submission,
          fileUrl: submission.fileContent
            ? getFileUrl(submission.fileContent)
            : null,
        })
      );
    }
    return uploadResponse;
  };

  const getFileUrl = fileContent => {
    const decodedFileString = window.atob(fileContent);
    const length = decodedFileString.length;
    const buffer = new ArrayBuffer(length);
    let fileByteArray = new Uint8Array(buffer);

    for (let i = 0; i < length; i++) {
      fileByteArray[i] = decodedFileString.charCodeAt(i);
    }

    const pdfBlob = new Blob([fileByteArray], { type: 'application/pdf' });
    return URL.createObjectURL(pdfBlob);
  };

  const sortJobSubmittalsByDateAscThenByIdDesc = submittals =>
    submittals.sort((a, b) => {
      const comparisonResult = a.submissionDate - b.submissionDate;
      if (comparisonResult === 0) {
        return a.id - b.id;
      }
      return comparisonResult;
    });

  const formatJobSubmittalOptions = submittals => {
    return sortJobSubmittalsByDateAscThenByIdDesc(submittals).map(
      submittal => ({
        ...submittal,
        name: `${getDateAsMMDD(submittal.submissionDate)} - ${submittal.id} - ${
          submittal.facilityName
        }`,
        startDate: getJsDateAsString(new Date(submittal.startDate)),
        value: submittal.id,
        isBranded: submittal.isBranded === null ? '' : submittal.isBranded,
      })
    );
  };

  const isConflictErrorInBlobFormat = err => {
    return (
      err?.response?.status === httpResponses.CONFLICT_ERROR_CODE &&
      err.response.data instanceof Blob
    );
  };

  const isBadRequestErrorInBlobFormat = err => {
    return (
      err?.response?.status === httpResponses.BAD_REQUEST &&
      err.response.data instanceof Blob
    );
  };

  const getErrorMessageAsStringFromBlob = async err => {
    const errorMessageAsBlob = err.response.data;
    const errorMessageAsText = await errorMessageAsBlob.text();
    return errorMessageAsText;
  };

  const createAndDownloadPdf = (httpMethod, path, data) => {
    return new Promise((resolve, reject) => {
      httpMethod(path, data, {}, 'blob')
        .then(res => downloadNamedPdf(res, resolve))
        .catch(async err => {
          const errorMessage = isConflictErrorInBlobFormat(err)
            ? await getErrorMessageAsStringFromBlob(err)
            : null;
          reject(errorMessage);
        });
    });
  };

  const buildUploadRequestObject = (
    formValues,
    filteredJobSubmissions,
    profileId,
    selectedChecklistIds,
    selectedCredentialIds
  ) => {
    const bhSubmittalPdfGenRequestDto = {
      profileId,
      saveSubmittalPdfToBullhorn: true,
      checklistIds: selectedChecklistIds,
      credentialIds: selectedCredentialIds,
      requestedSubmittals: buildRequestedSubmittalsArray(
        filteredJobSubmissions,
        formValues
      ),
    };
    return bhSubmittalPdfGenRequestDto;
  };

  const buildRequestedSubmittalsArray = (
    filteredJobSubmissions,
    formValues
  ) => {
    return filteredJobSubmissions.map(j => {
      const match = formValues.submittals.find(s => s.id === j.id);
      return {
        jobSubmittalId: j.id,
        recruiterComments: formValues.recruiterComments,
        pdfStyle: getPdfStyle(match.isBranded),
        ...match,
        ...j,
      };
    });
  };

  const getPdfStyle = isBrandedProp => {
    if (typeof isBrandedProp !== 'boolean') return isBrandedProp;
    return isBrandedProp ? 'BRANDED' : 'UNBRANDED';
  };

  return {
    getCandidateSubmittalData: function (candidateProfileId) {
      const statesPromise = get('/api/states');
      const candidatePromise = get(
        `${CANDIDATE_SUBMITTAL_DATA_ENDPOINT}/${candidateProfileId}`
      );
      return Promise.all([statesPromise, candidatePromise]).then(values => {
        const [completedStatesPromise, completedCandidatePromise] = values;
        const states = completedStatesPromise.data;
        const candidate = completedCandidatePromise.data;

        const formattedCandidate = formatCandidate(candidate, states);
        return formattedCandidate;
      });
    },
    doesCandidateHaveSubmittals: function (candidateProfileId) {
      return get(
        `${BULLHORN_JOB_SUBMISSIONS_ENDPOINT}/has-job-submissions?profileId=${candidateProfileId}`
      );
    },
    getDistanceBetweenCandidateAndFacility: matchId => {
      const params = `?jobSubmissionId=${matchId}`;
      const url = `${SUBMISSION_JOB_DISTANCE_ENDPOINT}${params}`;

      return new Promise(resolve => {
        get(url)
          .then(({ data }) => resolve(data))
          .catch(errResp => resolve(errResp));
      });
    },
    getDistancesBetweenCandidateAndFacilities: matchIds => {
      return new Promise((resolve, reject) => {
        post(SUBMISSION_JOB_DISTANCES_ENDPOINT, matchIds)
          .then(({ data }) => {
            data.isSuccessful
              ? resolve(data.resultData)
              : reject(data.errorMessage);
          })
          .catch(() => reject());
      });
    },
    getCandidateJobSubmittals: function (profileId) {
      return new Promise((resolve, reject) => {
        get(`${BULLHORN_JOB_SUBMISSIONS_ENDPOINT}?profileId=${profileId}`)
          .then(({ data }) => {
            resolve(formatJobSubmittalOptions(data));
          })
          .catch(error => reject(error));
      });
    },
    getCandidateWithJobSubmittals: function (profileId) {
      return new Promise((resolve, reject) => {
        get(
          `${BULLHORN_JOB_SUBMISSIONS_WITH_CANDITATE_ENDPOINT}?profileId=${profileId}`
        )
          .then(({ data }) => {
            data.jobSubmissions = formatJobSubmittalOptions(
              data.jobSubmissions
            );
            resolve(data);
          })
          .catch(({ data }) => reject(data));
      });
    },
    createAndDownloadSubmittalPdf: function (bhSubmittalPdfGenRequestDto) {
      const path = `${BULLHORN_SUBMITTAL_PDF_ENDPOINT}/create-submittal`;
      return new Promise((resolve, reject) => {
        post(path, bhSubmittalPdfGenRequestDto, {}, 'blob')
          .then(res => downloadNamedPdf(res, resolve))
          .catch(async err => {
            let errorMessage;

            if (
              isBadRequestErrorInBlobFormat(err) ||
              isConflictErrorInBlobFormat(err)
            ) {
              errorMessage = await getErrorMessageAsStringFromBlob(err);
            }

            reject(errorMessage);
          });
      });
    },
    uploadSubmittalsToBullhornOrDownloadSubmittalPdf: function (
      values,
      filteredJobSubmissions,
      profileId,
      selectedChecklistIds,
      selectedCredentialIds
    ) {
      const bhSubmittalPdfGenRequestDto = buildUploadRequestObject(
        values,
        filteredJobSubmissions,
        profileId,
        selectedChecklistIds,
        selectedCredentialIds
      );

      const path = `${BULLHORN_SUBMITTAL_PDF_ENDPOINT}/upload-to-bullhorn`;

      return new Promise((resolve, reject) => {
        post(path, bhSubmittalPdfGenRequestDto)
          .then(response => {
            response.data = formatResult(response.data);
            resolve(response);
          })
          .catch(async err => reject(err));
      });
    },
    createAndDownloadResumePdf: function (profileId, isBranded) {
      const path = `${BULLHORN_SUBMITTAL_PDF_ENDPOINT}/create-resume/${profileId}`;
      return createAndDownloadPdf(post, path, isBranded);
    },
    createAndDownloadSsnVerificationPdf: function (profileId) {
      const path = `${BULLHORN_SUBMITTAL_PDF_ENDPOINT}/create-health-trust-ssn-verification-pdf/${profileId}`;
      return createAndDownloadPdf(get, path, {});
    },
  };
}

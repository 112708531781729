import { useCandidateSubmittalDataApi } from './candidate-submittal-data-service';

export function useDistanceApi() {
  const {
    getDistanceBetweenCandidateAndFacility,
    getDistancesBetweenCandidateAndFacilities,
  } = useCandidateSubmittalDataApi();

  return {
    getDistanceToJobOrError: jobSubmissionId => {
      return getDistanceBetweenCandidateAndFacility(jobSubmissionId);
    },

    getDistancesToJobs: jobSubmissionIds => {
      return getDistancesBetweenCandidateAndFacilities(jobSubmissionIds);
    },
  };
}

import React from 'react';
import styles from './index.module.scss';

const FeedbackBanner = () => {
  return (
    <div className={styles.banner}>
      <div className={styles.centerAligned}>
        <p className={styles.bannerText}>
          For technical support, please create a{' '}
          <a
            target="_blank"
            rel="noreferrer"
            className="has-text-info"
            href="https://ingenovishealth.zendesk.com/hc/en-us/requests/new"
          >
            HelpDesk
          </a>{' '}
          ticket
        </p>
        <p className={styles.bannerText}>
          Feedback, comments from your traveler, or ideas to improve the
          dashboard? Please email{' '}
          <a
            rel="noreferrer"
            href="mailto:websitefeedback@Trustaff.com"
            className="has-text-info"
          >
            websitefeedback@Trustaff.com
          </a>
        </p>
      </div>
    </div>
  );
};
export default FeedbackBanner;

import React from 'react';
import PropTypes from 'prop-types';

const Tabs = ({ activeIndex, children: childrenProp, onTabClick }) => {
  const children = React.Children.map(childrenProp, (child, index) => {
    const isActive = activeIndex === index;

    return React.cloneElement(child, {
      isActive: isActive,
      onClick: onTabClick,
      index: index,
    });
  });

  return (
    <div className="tabs is-boxed">
      <ul>{children}</ul>
    </div>
  );
};

Tabs.propTypes = {
  activeIndex: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.object),
  onTabClick: PropTypes.func,
};

export default Tabs;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PopupNotification from '../popup-notification';
import { errorMessages } from '../../constants/error-messages';
import { useDocumentsApi } from '../../services/documents-service';

const ViewDocumentButton = ({
  documentTypeId,
  profileId,
  docName,
  buttonText,
}) => {
  const { downloadDocument } = useDocumentsApi();
  const [hasFetchError, setHasFetchError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const retrieveDocument = (documentTypeId, profileId) => {
    setIsLoading(true);
    downloadDocument(documentTypeId, profileId, docName)
      .then(doc => {
        let url = URL.createObjectURL(doc);
        window.open(url);
      })
      .catch(() => setHasFetchError(true))
      .finally(() => setIsLoading(false));
  };

  return (
    <>
      <button
        className={`button is-small is-info ${isLoading ? 'is-loading' : ''}`}
        disabled={isLoading}
        onClick={() => retrieveDocument(documentTypeId, profileId)}
      >
        {buttonText}
      </button>
      {hasFetchError && (
        <PopupNotification
          message={errorMessages.UNABLE_TO_FETCH}
          duration="medium"
          onClose={() => setHasFetchError(false)}
          notificationStyleClass="is-danger"
        />
      )}
    </>
  );
};

export default ViewDocumentButton;

ViewDocumentButton.propTypes = {
  documentTypeId: PropTypes.number.isRequired,
  profileId: PropTypes.number.isRequired,
  buttonText: PropTypes.string,
  docName: PropTypes.string,
};

ViewDocumentButton.defaultProps = {
  buttonText: 'View',
  docName: null,
};

import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import RecruiterApp from '../../apps/recruiter';

const Recruiter = ({ data, location }) => {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      <Helmet>
        <title>Recruiter - Trustaff</title>
      </Helmet>
      <RecruiterApp mounted={mounted} />
    </>
  );
};

export default Recruiter;

import { useBullhornCandidatesApi } from './bullhorn-candidates-service';
import { useAuth } from '../providers/auth-provider';

export function useCandidatesTableDataService() {
  const { getCandidatesFromBullhorn } = useBullhornCandidatesApi();
  const { getProfileId } = useAuth();

  const DASHBOARD_BASE_PATH = 'recruiter/dashboard';

  const addPathsToCandidatePages = candidates => {
    return candidates.map(candidate => {
      return {
        linkPath: `/${DASHBOARD_BASE_PATH}/candidates/${candidate.id}`,
        candidateDetailsLinkPath: `/${DASHBOARD_BASE_PATH}/candidates/${candidate.bullhornId}`,
        candidateSubmittalDetailsLinkPath: `/${DASHBOARD_BASE_PATH}/candidate-submittal-details/${candidate.id}`,
        shouldShowCandidateLinks: candidate.isLinked,
        ...candidate,
      };
    });
  };

  const getCandidatesFromApi = (paginationData, filterType, searchQuery) => {
    return getCandidatesFromBullhorn(
      getProfileId(),
      filterType,
      paginationData?.limit,
      paginationData?.offset,
      searchQuery
    );
  };

  return {
    getAndSetCandidatesTableData: function (
      filterType,
      tableStateCallbacks,
      paginationData,
      isLoading,
      searchQuery
    ) {
      if (!isLoading) tableStateCallbacks.setIsLoading(true);
      getCandidatesFromApi(paginationData, filterType, searchQuery)
        .then(data => {
          data.candidates = addPathsToCandidatePages(data.candidates);
          tableStateCallbacks.setCandidates(data.candidates);
          if (paginationData) {
            paginationData.totalCount = data.totalRecords;
            tableStateCallbacks.setPaginationData(paginationData);
          }
        })
        .catch(err => {
          tableStateCallbacks.setHasFetchError(true);
        })
        .finally(() => {
          tableStateCallbacks.setIsLoading(false);
        });
    },
  };
}

import React from 'react';
import { Router } from '@reach/router';
import styles from './index.module.scss';
import TableSection from '../table-section';
import CandidateSubmittalDetails from './candidate-submittal-details';
import SearchResults from '../search-results';
import CandidateSpecificRoutesWrapper from './candidate-specific-routes-wrapper';
import CreateSubmittals from './create-submittals';

const DashboardRoutes = ({ basepath }) => {
  return (
    <section className={`section ${styles.contentSection}`}>
      <Router basepath={basepath}>
        <CandidateSpecificRoutesWrapper path="/candidates/:bullhornId" />
        <SearchResults path="/candidates/search" />
        <TableSection path="/*" />
        <CandidateSubmittalDetails path="/candidate-submittal-details/:candidateProfileId" />
        <CreateSubmittals path="/create-submittals/:profileId" />
      </Router>
    </section>
  );
};

export default DashboardRoutes;

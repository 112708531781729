import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import ApiErrorMessage from '../../../../components/error-messages/api-error-message';
import LoadingSpinner from '../../../../components/loading-spinner';
import Modal from '../../../../components/modal';
import Select from '../../../common/components/forms/formik-inputs/select';
import Textarea from '../../../common/components/forms/formik-inputs/textarea';
import Checkbox from '../../../common/components/forms/formik-inputs/checkbox';
import { errorMessages } from '../../../../constants/error-messages';
import { httpResponses } from '../../../../constants/http-responses';
import { pdfStyleOptions } from '../../../../constants/pdf-style-options';
import validationSchema from './validation-schema';
import { useCandidateSubmittalDataApi } from '../../../../services/candidate-submittal-data-service';
import { useDistanceApi } from '../../../../services/distance-service';
import styles from './index.module.scss';
import mspNames from '../../../../constants/msp-names';
import { useAuth } from '../../../../providers/auth-provider';
import { navigate } from 'gatsby';

const SubmittalCreationSection = ({
  profileId,
  isBullhornEnv,
  isProfileComplete,
  selectedCredentialIds,
  selectedChecklistIds,
}) => {
  const {
    doesCandidateHaveSubmittals,
    getCandidateJobSubmittals,
    createAndDownloadSubmittalPdf,
    createAndDownloadSsnVerificationPdf,
    createAndDownloadResumePdf,
  } = useCandidateSubmittalDataApi();

  const { meta } = useAuth();
  const { getDistanceToJobOrError } = useDistanceApi();
  const [isLoadingHasSubmittals, setIsLoadingHasSubmittals] = useState(true);
  const [candidateHasSubmittals, setCandidateHasSubmittals] = useState(false);
  const [hasNotLinkedError, setHasNotLinkedError] = useState(false);
  const [hasGeneralError, setHasGeneralError] = useState(false);
  const [isLoadingSubmittals, setIsLoadingSubmittals] = useState(true);
  const [jobSubmittalOptions, setJobSubmittalOptions] = useState([]);
  const [errorFetchingSubmittals, setErrorFetchingSubmittals] = useState('');
  const [showCoversheetModal, setShowCoversheetModal] = useState(false);
  const [isCreatingSubmittalPdf, setIsCreatingSubmittalPdf] = useState(false);
  const [isCreatingSsnVerificationPdf, setIsCreatingSsnVerificationPdf] =
    useState(false);
  const [isCreatingResumePdf, setIsCreatingResumePdf] = useState(false);
  const [pdfCreationError, setPdfCreationError] = useState('');
  useState(false);
  const [isLoadingDistance, setIsLoadingDistance] = useState(false);
  const [shouldShowMspSyncCheckbox, setShouldShowMspSyncCheckbox] =
    useState(false);
  const [distanceToJobOrErrMessage, setDistanceToJobOrErrMessage] =
    useState(null);
  const hasError = () => hasNotLinkedError || hasGeneralError;
  const [showStyleDropdown, setShowStyleDropdown] = useState(false);

  const getButtonDisabledMessage = () => {
    let disabledMessage = '';

    if (!isProfileComplete)
      disabledMessage =
        'Candidate profile must be complete before creating a submittal packet';
    else if (!candidateHasSubmittals)
      disabledMessage = 'Candidate does not have any submittals in Bullhorn';
    else if (errorFetchingSubmittals) disabledMessage = errorFetchingSubmittals;

    return disabledMessage;
  };

  const onSubmit = (values, { resetForm }) => {
    setPdfCreationError('');
    if (values.msp === mspNames.HEALTH_TRUST) {
      createHealthTrustPdf(values);
      createSsnForVerificationPdf();
      createResumePdf(values);
    }

    createSubmittalPdf(values, resetForm);
  };

  const createHealthTrustPdf = values => {
    let doNotResetForm = null;
    let newValues = JSON.parse(JSON.stringify(values));
    newValues.pdfStyle = 'HEALTH_TRUST';
    return createSubmittalPdf(newValues, doNotResetForm);
  };

  const createSubmittalPdf = (values, resetForm) => {
    setIsCreatingSubmittalPdf(true);
    let milesToFacility = !distanceToJobOrErrMessage.hasError
      ? distanceToJobOrErrMessage.distanceInMiles
      : null;
    let bhSubmittalPdfGenRequestDto = {
      profileId,
      milesToFacility,
      ...values,
    };

    createAndDownloadSubmittalPdf(bhSubmittalPdfGenRequestDto)
      .then(() => {
        resetForm && resetForm();
        setShowStyleDropdown(false);
      })
      .catch(handleCreateSubmittalPdfErrorMessage)
      .finally(() => setIsCreatingSubmittalPdf(false));
  };

  const handleCreateSubmittalPdfErrorMessage = errMessage => {
    const errorDisplayMessage = errMessage ?? errorMessages.REQUEST_FAILED;
    setPdfCreationError(errorDisplayMessage);
  };

  const createSsnForVerificationPdf = () => {
    setIsCreatingSsnVerificationPdf(true);
    createAndDownloadSsnVerificationPdf(profileId)
      .catch(errorMessage => {
        setPdfCreationError(errorMessage || errorMessages.REQUEST_FAILED);
      })
      .finally(() => setIsCreatingSsnVerificationPdf(false));
  };

  const createResumePdf = values => {
    setIsCreatingResumePdf(true);
    createAndDownloadResumePdf(
      profileId,
      values.pdfStyle === pdfStyleOptions[0].value
    )
      .catch(errorMessage => {
        setPdfCreationError(errorMessage || errorMessages.REQUEST_FAILED);
      })
      .finally(() => setIsCreatingResumePdf(false));
  };

  const handleJobSubmittalChange = (
    submittalIdString,
    updateFormikFieldCallback
  ) => {
    const submittalId = parseInt(submittalIdString, 10);
    const option = jobSubmittalOptions.find(x => x.value === submittalId);

    updateFormikFieldCallback('msp', option.msp);
    setShowRightsourcingCheckbox(option, updateFormikFieldCallback, meta);

    updateFormikFieldCallback('facilityName', option.facilityName);
    updatePdfStyle(option, updateFormikFieldCallback);

    setIsLoadingDistance(true);
    getDistanceToJobOrError(submittalIdString)
      .then(resp => setDistanceToJobOrErrMessage(resp))
      .finally(() => setIsLoadingDistance(false));
  };

  const setShowRightsourcingCheckbox = (
    option,
    updateFormikFieldCallback,
    meta
  ) => {
    if (
      meta.shouldAllowSaveCandidateToMsp &&
      option.msp === mspNames.RIGHTSOURCING
    ) {
      setShouldShowMspSyncCheckbox(true);
    } else {
      updateFormikFieldCallback('syncSubmissionToMsp', false);
      setShouldShowMspSyncCheckbox(false);
    }
  };

  const updatePdfStyle = (selectedSubmittal, updateFormikFieldCallback) => {
    if (selectedSubmittal.isBranded !== null) {
      setShowStyleDropdown(false);
      updateFormikFieldCallback(
        'pdfStyle',
        selectedSubmittal.isBranded ? 'BRANDED' : 'UNBRANDED'
      );
    } else {
      updateFormikFieldCallback('pdfStyle', '');
      setShowStyleDropdown(true);
    }
  };

  useEffect(() => {
    if (!isBullhornEnv) return;
    doesCandidateHaveSubmittals(profileId)
      .then(response => {
        setCandidateHasSubmittals(response.data);
      })
      .catch(err => {
        err?.response?.status === httpResponses.NOT_FOUND
          ? setHasNotLinkedError(true)
          : setHasGeneralError(true);
      })
      .finally(() => {
        setIsLoadingHasSubmittals(false);
      });
  }, [isBullhornEnv]);

  useEffect(() => {
    if (!isLoadingHasSubmittals && candidateHasSubmittals)
      getCandidateJobSubmittals(profileId)
        .then(response => {
          setJobSubmittalOptions(response);
        })
        .catch(() => {
          setErrorFetchingSubmittals(
            "There was an error fetching the candidate's job submittals."
          );
        })
        .finally(() => setIsLoadingSubmittals(false));
  }, [candidateHasSubmittals, isLoadingHasSubmittals]);

  const isAnyActionSubmitting =
    isCreatingSubmittalPdf ||
    isCreatingResumePdf ||
    isCreatingSsnVerificationPdf;

  selectedCredentialIds = selectedCredentialIds ?? [];
  selectedChecklistIds = selectedChecklistIds ?? [];

  return (
    isBullhornEnv && (
      <>
        <h3 className={`is-subtitle is-size-3`}>Create Submittal Packet</h3>
        <h6 className="has-text-weight-semibold">
          Note - you must create a submittal in Bullhorn first to be able to
          select it as a coversheet
        </h6>
        <div className={`has-text-right ${styles.container}`}>
          <button
            onClick={() =>
              navigate(`../../create-submittals/${profileId}`, {
                state: { selectedCredentialIds, selectedChecklistIds },
              })
            }
            className="button is-small is-info"
            title={getButtonDisabledMessage()}
            disabled={!candidateHasSubmittals || !isProfileComplete}
            type="button"
          >
            Create Submittal Packet
          </button>
        </div>
        {hasError() && (
          <div className={styles.container}>
            {hasGeneralError && (
              <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_FETCH} />
            )}
            {hasNotLinkedError && (
              <ApiErrorMessage
                errorMsg="This profile is not linked to a Bullhorn account. Please create a Help Desk ticket to resolve this issue."
                errorAddendum={null}
              />
            )}
          </div>
        )}
        {!isLoadingSubmittals && (
          <Formik
            initialValues={{
              pdfStyle: '',
              jobSubmittalId: '',
              recruiterComments: '',
              saveSubmittalPdfToBullhorn: true,
              syncSubmissionToMsp: false,
              msp: '',
              facilityName: '',
            }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, setFieldValue, values }) => (
              <Form onSubmit={handleSubmit}>
                <Modal
                  title="Create Submittal Packet"
                  message={
                    <>
                      <div>
                        To generate a submittal packet, select a coversheet.
                      </div>
                      <LoadingSpinner isLoading={isLoadingSubmittals} />

                      <Select
                        name="jobSubmittalId"
                        labelText="Coversheet Job"
                        labelClass={`is-size-7 ${styles.coversheetFormLabel}`}
                        options={jobSubmittalOptions}
                        onChange={event =>
                          handleJobSubmittalChange(
                            event.target.value,
                            setFieldValue
                          )
                        }
                      />

                      {isLoadingDistance ? (
                        <div className={styles.distanceContainer}>
                          <span>Calculating Distance... </span>
                          <span className={styles.distanceItem}>
                            <LoadingSpinner
                              isLoading={true}
                              isResponsive={true}
                            />{' '}
                          </span>
                        </div>
                      ) : distanceToJobOrErrMessage != null ? (
                        <div className={styles.distanceItem}>
                          {!distanceToJobOrErrMessage.hasError
                            ? `Miles to selected job: ${distanceToJobOrErrMessage.distanceInMiles}`
                            : `Unable to calculate distance: ${distanceToJobOrErrMessage?.error}`}
                        </div>
                      ) : (
                        <div></div>
                      )}

                      {showStyleDropdown && (
                        <Select
                          name="pdfStyle"
                          labelText="PDF Style"
                          labelClass={`is-size-7 ${styles.coversheetFormLabel}`}
                          options={pdfStyleOptions}
                        />
                      )}

                      <Textarea
                        name="recruiterComments"
                        labelText="Candidate Highlights"
                        labelClass={`is-size-7 ${styles.coversheetFormLabel}`}
                        shouldCheckForCapsLock={true}
                      />

                      <Checkbox
                        name="saveSubmittalPdfToBullhorn"
                        labelText="Also upload submittal PDF to Bullhorn"
                        labelClass={`is-size-7 ${styles.coversheetFormLabel}`}
                      />

                      {shouldShowMspSyncCheckbox && (
                        <Checkbox
                          name="syncSubmissionToMsp"
                          labelText="Sync submission to Rightsourcing"
                          labelClass={`is-size-7 ${styles.coversheetFormLabel}`}
                        />
                      )}
                    </>
                  }
                  isOpen={showCoversheetModal}
                  confirmButtonModifierClass={'is-info'}
                  disableConfirmButton={isAnyActionSubmitting}
                  confirmButtonText={`${
                    values.msp === mspNames.HEALTH_TRUST
                      ? 'Download All Submittal Documents'
                      : 'Get Submittal'
                  }`}
                  messageModifierClass={'is-info'}
                  onConfirm={handleSubmit}
                  onCancel={() => {
                    setPdfCreationError('');
                    setShowCoversheetModal(false);
                  }}
                  showCancelButton={true}
                  isSubmitting={isCreatingSubmittalPdf}
                  hasApiError={!!pdfCreationError}
                  apiErrorMessage={pdfCreationError}
                />
              </Form>
            )}
          </Formik>
        )}
      </>
    )
  );
};

SubmittalCreationSection.propTypes = {
  profileId: PropTypes.string.isRequired,
};

export default SubmittalCreationSection;

import apiService from './api-service';
import { useAuthenticatedRequest } from '../hooks/api-hooks';

export function getChecklistLookupValues() {
  return apiService.get('/api/skills-checklist/form-group-display-names');
}

export function getChecklistDetails(checklistId) {
  return apiService.get(`/api/skills-checklist/with-form-ids/${checklistId}`);
}

export function useSkillsChecklistsApi() {
  const { get, post, put, deleteRequest } = useAuthenticatedRequest();

  return {
    getSkillsChecklists: function (profileId) {
      return get(
        `/api/profiles/${profileId}/get-checklists-with-progress-status`
      );
    },
    addSkillsChecklist: function (profileId, checklistId) {
      return post(`/api/profiles/${profileId}/start-checklist/${checklistId}`);
    },
    deleteSkillsChecklist: function (profileId, checklistId) {
      return deleteRequest(
        `/api/profiles/${profileId}/delete-checklist/${checklistId}`
      );
    },
    getFormDataAndAnswers: function (profileId, checklistId, formId) {
      return new Promise((resolve, reject) => {
        const formDataPromise = apiService.get(
          `/api/skills-checklist/${checklistId}/form/${formId}`
        );
        const answersDataPromise = get(
          `/api/profiles/${profileId}/checklist/${checklistId}/form/${formId}/answers`
        );

        // TODO: Break these out into functions
        // Just moving them out of react for now

        Promise.all([formDataPromise, answersDataPromise]).then(values => {
          const [formResponse, answersResponse] = values;
          const formData = formResponse.data;
          const answersData = answersResponse.data;

          // update answers values to be an array and handle multipart and checkbox type questions
          const reformattedAnswers = answersData.map(answerObj => {
            let { answer, ...restProps } = answerObj;
            return {
              ...restProps,
              answers: reformatIntoAnswersArray(answer),
            };
          });

          // Create default grouped answers that will be populated with actual answers
          let groupedAnswers = formData.questionGroups.map(group => ({
            questions: group.questions.map(({ id, parts }) => {
              const reformattedAnswer = reformattedAnswers.find(
                ({ questionId }) => questionId === id
              );
              const answerParts = hasBeenAnsweredByUser(reformattedAnswer)
                ? reformattedAnswer.answers
                : parts.map(() => {
                    return isTypeCheckboxes(formData) ? false : '';
                  });
              return {
                id: reformattedAnswer.id,
                questionId: id,
                answers: answerParts,
              };
            }),
          }));

          resolve([formData, groupedAnswers]);
        });
      });
    },
    saveFormAnswers: function (profileId, checklistId, formId, answerGroups) {
      let flattenedAnswers = [];
      answerGroups.forEach(answerGroup => {
        answerGroup.questions.forEach(question => {
          flattenedAnswers.push(question);
        });
      });

      let reformattedAnswers = flattenedAnswers.map(answerObj => {
        const { answers, ...restProps } = answerObj;
        return {
          ...restProps,
          answer: answers.join('|'), // back end expects two part answers to be pipe separated
        };
      });

      return put(
        `/api/profiles/${profileId}/checklist/${checklistId}/form/${formId}/answers`,
        reformattedAnswers
      );
    },
    renewSkillsChecklists: function (profileId) {
      return post(`/api/profiles/${profileId}/checklists/refresh-all`);
    },
  };
}

function reformatIntoAnswersArray(answer) {
  const answersArray = answer === null ? [''] : answer.split('|');
  return answersArray.map(answer => {
    // convert checkbox type answers from boolean strings to boolean type
    if (answer === 'true') return true;
    if (answer === 'false') return false;
    return answer;
  });
}

function isTypeCheckboxes(formData) {
  return formData.questionGroups[0].questions[0].type === 'checkbox';
}

function hasBeenAnsweredByUser(answer) {
  return answer.answers[0].length || typeof answer.answers[0] === 'boolean';
}

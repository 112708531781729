import { useAuthenticatedRequest } from '../hooks/api-hooks';

export function useCandidateCredentialsApi() {
  const { get } = useAuthenticatedRequest();
  const CANDIDATE_CREDENTIALS_ENDPOINT = '/api/candidate-credentials';

  return {
    getCandidateCredentials: function (profileId) {
      return get(`${CANDIDATE_CREDENTIALS_ENDPOINT}/${profileId}`);
    },
  };
}

import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

/* eslint-disable jsx-a11y/no-autofocus */

const SearchBar = React.forwardRef(
  (
    { placeholderText, searchQuery, onSearch, autoFocus, preventAutoSearch },
    ref
  ) => {
    const SEARCH_DELAY = 500;
    const [internalQueryText, setInternalQueryText] = useState(searchQuery);

    useEffect(() => {
      if (!preventAutoSearch) {
        const timer = setTimeout(() => {
          onSearch(internalQueryText);
        }, SEARCH_DELAY);
        return () => clearTimeout(timer);
      }
    }, [internalQueryText]);

    useEffect(() => {
      setInternalQueryText(searchQuery);
    }, [searchQuery]);

    const handleSubmit = e => {
      e.preventDefault();
      onSearch(internalQueryText);
    };

    return (
      <form
        className={`field container has-addons has-addons-centered`}
        onSubmit={handleSubmit}
      >
        <div className="control">
          <input
            ref={ref}
            autoFocus={autoFocus}
            value={internalQueryText}
            className={`input ${styles.input}`}
            onChange={e => setInternalQueryText(e.target.value)}
            type="text"
            placeholder={placeholderText}
          />
        </div>
        <div className="control">
          <button className="button is-info" type="submit">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </form>
    );
  }
);

export default SearchBar;

SearchBar.propTypes = {
  placeholderText: PropTypes.string,
  searchQuery: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  autoFocus: PropTypes.bool,
  preventAutoSearch: PropTypes.bool,
};

SearchBar.defaultProps = {
  placeholderText: 'Search',
  searchQuery: '',
  autoFocus: false,
  preventAutoSearch: true,
};

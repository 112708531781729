import React from 'react';
import { Router } from '@reach/router';
import CandidateDetails from '../dashboard/candidate-details';

const CandidateSpecificRoutesWrapper = ({ bullhornId }) => {
  return (
    <Router>
      <CandidateDetails path="/" bullhornId={bullhornId} />
    </Router>
  );
};

export default CandidateSpecificRoutesWrapper;

import React, { useEffect, useState } from 'react';
import styles from './submittal-options.module.scss';
import { useCandidateCredentialsApi } from '../../../../../services/candidate-credentials-service';
import PopupNotification from '../../../../../components/popup-notification';
import CREDENTIAL_STATUSES from '../../../../../constants/credential-statuses';
import CREDENTIAL_TYPES from '../../../../../constants/credential-types';
import { formatDate } from '../../../../../utils/format-helpers';
import SubmittalOptionsSelection from './submittal-options-selection';

const SubmittalOptions = ({
  profileId,
  selectedCredentialIds,
  setSelectedCredentialIds,
  selectedChecklistIds,
  setSelectedChecklistIds,
}) => {
  const { getCandidateCredentials } = useCandidateCredentialsApi();

  const [checklists, setChecklists] = useState([]);
  const [credentials, setCredentials] = useState([]);
  const [apiMessage, setApiMessage] = useState('');
  const [showPopup, setShowPopup] = useState(null);

  useEffect(() => {
    getCandidateCredentials(profileId)
      .then(({ data }) => {
        filterAndSetChecklists(data);
        filterAndSetCredentials(data);
      })
      .catch(() => {
        setApiMessage('Unable to get credentials from Bullhorn.');
        setShowPopup(true);
      });
  }, []);

  const filterAndSetChecklists = allCredentials => {
    const checklists = allCredentials.filter(
      c => c.type === CREDENTIAL_TYPES.SKILLS_CHECKLIST
    );
    setChecklists(checklists);
    setSelectedChecklistIds(
      getCredentialIdsByExpirationStatus(checklists, [
        CREDENTIAL_STATUSES.CURRENT,
      ])
    );
  };

  const filterAndSetCredentials = allCredentials => {
    const credentials = allCredentials.filter(
      c => c.type !== CREDENTIAL_TYPES.SKILLS_CHECKLIST
    );
    setCredentials(credentials);
    setSelectedCredentialIds(
      getCredentialIdsByExpirationStatus(credentials, [
        CREDENTIAL_STATUSES.CURRENT,
        CREDENTIAL_STATUSES.EXPIRING_SOON,
      ])
    );
  };

  const getCredentialIdsByExpirationStatus = (anyCredentials, statuses) => {
    return anyCredentials
      .filter(c => statuses.includes(c.expirationStatus))
      .map(c => c.id);
  };

  const handleSkillsChecklistOnChange = id => {
    let updatedSelectedChecklists = [...selectedChecklistIds];
    const index = updatedSelectedChecklists.findIndex(c => c === id);
    const idAlreadySelected = index >= 0;
    if (idAlreadySelected) {
      updatedSelectedChecklists.splice(index, 1);
    } else {
      updatedSelectedChecklists.push(id);
    }
    setSelectedChecklistIds(updatedSelectedChecklists);
  };

  const formatForCheckboxes = (anyCredentials, isSkillsChecklist) => {
    return anyCredentials.map(c => ({
      ...c,
      location: c.location,
      name: c.name,
      validThruDateSpan: getValidThroughDateElementForCredentials(
        c.expirationDate
      ),
      isDisplayed: isSkillsChecklist
        ? selectedChecklistIds.includes(c.id)
        : selectedCredentialIds.includes(c.id),
      isCheckBoxDisabled: false,
      labelClassName:
        c.expirationStatus === CREDENTIAL_STATUSES.EXPIRED
          ? styles.checkboxItemBold
          : '',
      status: c.expirationStatus,
      isCompact: c.isCompact,
    }));
  };

  const getValidThroughDateElementForCredentials = date => {
    const formattedDate = formatDate(date);

    return date ? (
      <>
        <span
          className={`${styles.validThru}`}
        >{`(Valid thru: ${formattedDate})`}</span>
      </>
    ) : (
      <></>
    );
  };

  const getCompactTag = isCompact => {
    if (!isCompact) {
      return '';
    }
    return <span className={`${styles.tagboxCompact}`}>COMPACT</span>;
  };

  const getStatusTag = status => {
    switch (status) {
      default:
      case CREDENTIAL_STATUSES.CURRENT:
        return (
          <span className={`${styles.tagboxCredentialCurrent}`}>CURRENT</span>
        );

      case CREDENTIAL_STATUSES.UNKNOWN:
        return (
          <span className={`${styles.tagboxCredentialUnknown}`}>UNKNOWN</span>
        );
      case CREDENTIAL_STATUSES.EXPIRING_SOON:
        return (
          <span className={`${styles.tagboxCredentialExpiringSoon}`}>
            EXPIRING SOON
          </span>
        );

      case CREDENTIAL_STATUSES.EXPIRED:
        return (
          <span className={`${styles.tagboxCredentialExpired}`}>EXPIRED</span>
        );
    }
  };

  const handleCredentialsOnChange = id => {
    let updatedSelectedCredentials = [...selectedCredentialIds];
    const index = updatedSelectedCredentials.findIndex(c => c === id);
    const idAlreadySelected = index >= 0;
    if (idAlreadySelected) {
      updatedSelectedCredentials.splice(index, 1);
    } else {
      updatedSelectedCredentials.push(id);
    }
    setSelectedCredentialIds(updatedSelectedCredentials);
  };
  return (
    <>
      <h3 className={`is-subtitle is-size-3`}>Submittal Options</h3>
      <SubmittalOptionsSelection
        sectionTitle="Skills Checklist"
        instructions="Select which checklists you would like to include:"
        items={formatForCheckboxes(checklists, true)}
        getTagElementForStatus={getStatusTag}
        handleOnChange={handleSkillsChecklistOnChange}
        noMembersInCollectionMessage={
          'Skills Checklists are incomplete or expired before September 20, 2023'
        }
        getCompactTag={getCompactTag}
      />
      <SubmittalOptionsSelection
        sectionTitle="Credentials"
        instructions={'Select which credentials you would like to include:'}
        items={formatForCheckboxes(credentials)}
        getTagElementForStatus={getStatusTag}
        handleOnChange={handleCredentialsOnChange}
        noMembersInCollectionMessage={
          'Credentials have not been provided for the Candidate'
        }
        getCompactTag={getCompactTag}
      />
      {showPopup && (
        <PopupNotification
          message={apiMessage}
          onClose={() => setShowPopup(false)}
          notificationStyleClass={'is-danger'}
        />
      )}
    </>
  );
};

export default SubmittalOptions;

import React from 'react';
import styles from './index.module.scss';
import DistanceToFacilityColumn from './distance-to-facility-column';
import { pdfStyleOptions } from '../../../../../constants/pdf-style-options';
import Select from '../../../../common/components/forms/formik-inputs/select';

const MatchesTable = ({
  values,
  initialSubmittalOptions,
  selectedJobSubmittalIds,
  setSelectedJobSubmittalIds,
  haveSubmissionDistancesLoaded,
}) => {
  const isSelected = id => selectedJobSubmittalIds.includes(id);

  const onCheckboxClick = id => {
    const updated = [...selectedJobSubmittalIds];
    if (!updated.includes(id)) {
      updated.push(id);
    } else {
      const index = updated.indexOf(id);
      updated.splice(index, 1);
    }
    setSelectedJobSubmittalIds(updated);
  };

  const getPdfStyle = (submittal, index) => {
    if (initialSubmittalOptions[index].isBranded === '') {
      return (
        <Select
          name={`submittals[${index}].isBranded`}
          title="Select the checkbox to choose a PDF style"
          options={pdfStyleOptions}
          isRounded={false}
          disabled={!isSelected(submittal.id)}
        />
      );
    } else {
      return (
        <span name={`submittals[${index}].isBranded`}>
          {initialSubmittalOptions[index].isBranded ? 'Branded' : 'Unbranded'}{' '}
        </span>
      );
    }
  };

  const getNumMatchesMessage = () => {
    const numMatches = selectedJobSubmittalIds.length;

    let message = String(numMatches);
    message += numMatches === 1 ? ' match selected' : ' matches selected';

    return message;
  };

  return (
    <table className={`table is-fullwidth ${styles.table}`}>
      <thead>
        <tr>
          <th>{''}</th>
          <th scope="col">ID</th>
          <th scope="col">Match status</th>
          <th scope="col">Facility</th>
          <th scope="col">Job title</th>
          <th className="has-text-right" scope="col">
            Start date
          </th>
          <th className="has-text-right" scope="col">
            Miles from facility
          </th>
          <th scope="col">PDF style</th>
        </tr>
      </thead>
      <tbody>
        {values.submittals.map((submittal, index) => (
          <tr
            key={submittal.id}
            className={`${isSelected(submittal.id) ? styles.isSelected : ''}`}
          >
            <th scope="row">
              <input
                type="checkbox"
                checked={isSelected(submittal.id)}
                onChange={() => onCheckboxClick(submittal.id)}
              />
            </th>
            <td name={`submittals[${index}].id`}>{submittal.id}</td>
            <td name={`submittals[${index}].status`}>{submittal.status}</td>
            <td name={`submittals[${index}].facilityName`}>
              {submittal.facilityName}
            </td>
            <td name={`submittals[${index}].jobTitle`}>{submittal.jobTitle}</td>
            <td
              className="has-text-right"
              name={`submittals[${index}].startDate`}
            >
              {submittal.startDate}
            </td>
            <td
              className="has-text-right"
              name={`submittals[${index}].distanceData`}
            >
              <DistanceToFacilityColumn
                distanceData={submittal.distanceData}
                haveSubmissionDistancesLoaded={haveSubmissionDistancesLoaded}
              />
            </td>
            <td>{getPdfStyle(submittal, index)}</td>
          </tr>
        ))}
      </tbody>
      <tfoot className={`${styles.tableFooter}`}>
        <tr>
          <td colSpan="8">
            <strong>{getNumMatchesMessage()}</strong>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default MatchesTable;

import React from 'react';
import { Link } from '@reach/router';

const SubmittalDetailsLink = ({ rowData, dataKey, isButton }) => {
  const linkPath = rowData.candidateSubmittalDetailsLinkPath;

  if (isButton) {
    return rowData.shouldShowCandidateLinks ? (
      <Link to={linkPath} className="button is-small is-info">
        Details
      </Link>
    ) : null;
  } else {
    return rowData.shouldShowCandidateLinks ? (
      <Link to={linkPath}>{rowData[dataKey]}</Link>
    ) : (
      rowData[dataKey]
    );
  }
};

export default SubmittalDetailsLink;

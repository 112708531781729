import React, { useState, useEffect } from 'react';
import { authRoles } from '../../constants/auth-roles';
import Dashboard from './components/dashboard';
import { Router, useLocation, navigate } from '@reach/router';
import RecruitersProvider from './providers/recruiters-provider';
import SetPassword from './components/set-password';
import ProtectedRoute from '../../components/protected-route';
import { useAuth } from '../../providers/auth-provider';
import LoadingSpinner from '../../components/loading-spinner';
import { externalLinks } from '../../constants/external-links';

const RecruiterAppWrapper = () => {
  return (
    <RecruitersProvider>
      <Router basepath="/recruiter">
        <RecruiterRoutes default />
      </Router>
    </RecruitersProvider>
  );
};

const RecruiterRoutes = () => {
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const {
    isAttemptingLoginFromTalentStack,
    attemptToLogInTalentStackUser,
    talentStackUserIsAttemptingToViewRestrictedRoute,
    userShouldBeUsingTalentStack,
  } = useAuth();

  useEffect(() => {
    if (talentStackUserIsAttemptingToViewRestrictedRoute(location.pathname)) {
      setIsLoading(true);
      navigate(externalLinks.TALENT_STACK_HOME);
    } else if (isAttemptingLoginFromTalentStack()) {
      (async () => {
        await attemptToLogInTalentStackUser();
        setIsLoading(false);
      })();
    } else if (userShouldBeUsingTalentStack()) {
      setIsLoading(true);
      navigate(externalLinks.TALENT_STACK_HOME);
    } else {
      setIsLoading(false);
    }
  }, [location.pathname]);

  return isLoading ? (
    <LoadingSpinner isLoading={isLoading} />
  ) : (
    <Router>
      <ProtectedRoute
        path="/dashboard/*"
        acceptedRoles={[authRoles.RECRUITER, authRoles.ADMIN_ASSISTANT]}
        component={Dashboard}
      />
      <SetPassword path="set-password/" />
    </Router>
  );
};

export default RecruiterAppWrapper;

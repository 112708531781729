import React from 'react';
import ModularModal from '../../../../../../components/modular-modal';
import {
  SubmittalUploadInProgress,
  SubmittalUploadComplete,
  SubmittalUploadFailedDocumentsTable,
} from '../submittal-upload-modal-state-inserts';
import SubmittalUploadStates from '../../../../../../constants/submittal-uploads-states';
import PropTypes from 'prop-types';

const SubmittalUploadStateModal = ({
  uploadState,
  failedUploadsObject,
  closeModal,
}) => {
  switch (uploadState) {
    case SubmittalUploadStates.IN_PROGRESS:
      return (
        <ModularModal isOpen={true} showButtonFooter={false}>
          <SubmittalUploadInProgress />
        </ModularModal>
      );
    case SubmittalUploadStates.SUCCESS:
      return (
        <ModularModal
          isOpen={true}
          onClose={() => closeModal()}
          showButtonFooter={true}
        >
          <SubmittalUploadComplete isSuccess={true} />
        </ModularModal>
      );
    case SubmittalUploadStates.ERROR:
      return (
        <ModularModal
          isOpen={true}
          onClose={() => closeModal()}
          showButtonFooter={true}
        >
          <SubmittalUploadComplete
            isSuccess={false}
            errorMessage={failedUploadsObject?.submissionAgnosticErrorMessage}
          />
        </ModularModal>
      );
    case SubmittalUploadStates.PARTIAL_ERROR:
      return (
        <>
          {failedUploadsObject && (
            <ModularModal
              isOpen={true}
              headerText="Submittal packet errors"
              headerModifierClass={'is-info'}
              onClose={() => closeModal()}
              showButtonFooter={true}
            >
              <SubmittalUploadFailedDocumentsTable
                failedSubmissions={failedUploadsObject?.failedSubmissions}
              />
            </ModularModal>
          )}
        </>
      );

    default:
    case SubmittalUploadStates.NONE:
      return <></>;
  }
};

SubmittalUploadStateModal.propTypes = {
  uploadState: PropTypes.string.isRequired,
  failedUploadsObject: PropTypes.object,
  closeModal: PropTypes.func.isRequired,
};

export default SubmittalUploadStateModal;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './index.module.scss';

/* eslint-disable react-hooks/exhaustive-deps */

const ModularModal = ({
  isOpen,
  headerText,
  headerModifierClass,
  isHeaderTextCentered,
  showCancelIconButton,
  onClose,
  children,
  showButtonFooter,
}) => {
  return (
    <div className={`modal ${isOpen ? 'is-active' : ''}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <div className={`message ${headerModifierClass}`}>
          {headerText && (
            <div
              className={`message-header ${
                isHeaderTextCentered ? styles.centeredHeader : ''
              }`}
            >
              <h2>{headerText}</h2>
              {showCancelIconButton && (
                <button
                  className="delete"
                  aria-label="delete"
                  type="button"
                  onClick={() => onClose()}
                ></button>
              )}
            </div>
          )}
          <div className={styles.children}>{children}</div>
          {showButtonFooter && (
            <div>
              <hr className={`${styles.dividingLine}`} />
              <div className={`${styles.buttonFooter}`}>
                <button
                  className={`button is-info`}
                  onClick={() => {
                    onClose();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

ModularModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  isTitleCentered: PropTypes.bool,
  showModalCloseButton: PropTypes.bool,
  onClose: PropTypes.func,
  showButtonFooter: PropTypes.bool,
  showCancelIconButton: PropTypes.bool,
};

ModularModal.defaultProps = {
  showModalCloseButton: true,
  isTitleCentered: false,
  showButtonFooter: false,
  showCancelIconButton: false,
};

export default ModularModal;

import React from 'react';
import CandidateAppStatus from '../apps/recruiter/components/dashboard/candidate-app-status';
import ViewPdfButton from '../apps/recruiter/components/view-pdf-button';
import EditLink from '../apps/recruiter/components/dashboard/edit-link';
import SubmittalDetailsLink from '../apps/recruiter/components/dashboard/submittal-details-link';
import BullhornProfileLink from '../apps/recruiter/components/dashboard/bullhorn-profile-link';

export const generateCandidateTableDataColumns =
  getCandidateDataFromBullhorn => {
    const columns = [
      {
        heading: 'Name',
        dataKey: 'name',
        dataRenderComponent: <SubmittalDetailsLink />,
      },
      { heading: 'Specialty', dataKey: 'primarySpecialty' },
      { heading: 'Email', dataKey: 'email' },
      {
        heading: 'App Status',
        dataKey: 'status',
        dataRenderComponent: <CandidateAppStatus />,
      },
      { heading: 'Last Updated', dataKey: 'lastUpdated' },
      {
        heading: 'Bullhorn ID',
        dataKey: 'bullhornId',
        dataRenderComponent: <BullhornProfileLink />,
      },
      {
        dataKey: 'id',
        dataRenderComponent: <ViewPdfButton />,
      },
      !getCandidateDataFromBullhorn && {
        dataKey: 'status',
        dataRenderComponent: <EditLink />,
      },
      {
        dataKey: 'id',
        dataRenderComponent: <SubmittalDetailsLink isButton={true} />,
      },
    ].filter(column => !!column);

    return columns;
  };

import React, { useEffect, useState } from 'react';
import Table from '../../../../components/table';
import styles from './index.module.scss';
import { candidateFilterTypeIds } from '../../../../constants/recruiter-candidate-filter-ids';
import { useCandidatesTableDataService } from '../../../../services/candidates-table-data-service';
import { usePaginationService } from '../../../../services/pagination-service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { navigate } from 'gatsby';
import FeedbackBanner from '../feedback-banner';
import { getParamFromUrl } from '../../../../utils/url-helpers';
import { useAuth } from '../../../../providers/auth-provider';
import { generateCandidateTableDataColumns } from '../../../../utils/candidate-table-data-helper';

const SearchResults = ({ location }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [candidates, setCandidates] = useState([]);
  const [hasFetchError, setHasFetchError] = useState(false);
  const [query, setQuery] = useState('');
  const { meta } = useAuth();
  const { getAndSetCandidatesTableData } = useCandidatesTableDataService();
  const { getDefaultPaginationSettings } = usePaginationService();
  const [paginationData, setPaginationData] = useState(
    getDefaultPaginationSettings()
  );

  const tableStateCallbacks = {
    setCandidates: setCandidates,
    setHasFetchError: setHasFetchError,
    setIsLoading: setIsLoading,
    setPaginationData: setPaginationData,
  };

  useEffect(() => {
    const searchQuery = getParamFromUrl('query');

    setQuery(searchQuery);
    getAndSetCandidatesTableData(
      candidateFilterTypeIds.TEXT_SEARCH,
      tableStateCallbacks,
      paginationData,
      isLoading,
      searchQuery
    );
  }, [location]);

  return (
    <>
      <div className={styles.contentContainer}>
        <button
          className={`button is-primary is-outlined is-square ${styles.backButton}`}
          onClick={() => navigate(-1)}
        >
          <span className="icon ">
            <FontAwesomeIcon icon={faChevronLeft} />
          </span>
        </button>
        <div
          className={
            !isLoading && !candidates.length && !hasFetchError
              ? styles.hidden
              : ''
          }
        >
          <Table
            columns={generateCandidateTableDataColumns(
              meta.getCandidateDataFromBullhorn
            )}
            isLoading={isLoading}
            rowData={candidates}
            tableClass={`is-fullwidth`}
            hasFetchError={hasFetchError}
          />
        </div>
        {!isLoading && !candidates.length && !hasFetchError && (
          <p className={`notification is-info ${styles.emptyResultsMessage}`}>
            No candidates found matching "{query}"
          </p>
        )}
      </div>
      <FeedbackBanner />
    </>
  );
};

export default SearchResults;

import React from 'react';
import { documentTypeIds } from '../../../../constants/document-types';
import PropTypes from 'prop-types';
import ViewDocumentButton from '../../../../components/view-document-button';

const ViewPdfButton = ({ rowData, dataKey }) => {
  return rowData.isMarkedCompleteInCrm &&
    rowData.isChecklistSectionComplete &&
    rowData.shouldShowCandidateLinks ? (
    <ViewDocumentButton
      profileId={rowData[dataKey]}
      documentTypeId={documentTypeIds.PROFILE_PDF}
      buttonText="View"
    />
  ) : null;
};

ViewPdfButton.propTypes = {
  rowData: PropTypes.object,
  dataKey: PropTypes.string,
};

export default ViewPdfButton;

import React, { useEffect, useState } from 'react';
import styles from './index.module.scss';
import { errorMessages } from '../../../../constants/error-messages';
import { documentTypeIds } from '../../../../constants/document-types';
import { getFormattedDate } from '../../../../utils/format-helpers';
import { useDocumentsApi } from '../../../../services/documents-service';
import Table from '../../../../components/table';
import TableViewDocButton from './table-view-doc-button';
import TableDeleteDocButton from './table-delete-doc-button';
import ApiErrorMessage from '../../../../components/error-messages/api-error-message';
import PopupNotification from '../../../../components/popup-notification';
import FileUploadButton from '../../../../components/file-upload-button';

const SupplementalDocsSection = ({ candidateProfileId }) => {
  const {
    getAllSupplementalDocuments,
    uploadDocument,
    deleteDocument,
  } = useDocumentsApi();

  const [docsList, setDocsList] = useState([{}]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasFetchError, setHasFetchError] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [docBeingDeleted, setDocBeingDeleted] = useState(null);
  const [popNotification, setPopNotification] = useState(null);

  useEffect(() => {
    getAllSupplementalDocuments(candidateProfileId)
      .then(({ data }) => {
        const docs = data;
        docs.forEach(doc => {
          doc.lastUpdated = getFormattedDate(doc.lastUpdated);
        });
        setDocsList(docs);
      })
      .catch(() => setHasFetchError(true))
      .finally(() => setIsLoading(false));
  }, []);

  const handleFileUpload = file => {
    setIsUploading(true);
    uploadDocument(
      candidateProfileId,
      file,
      documentTypeIds.SUPPLEMENTAL_DOCUMENT
    )
      .then(({ data }) => {
        handleUploadSuccess(data);
      })
      .catch(err => {
        const errorMessage =
          err.response && typeof err.response.data === 'string'
            ? err.response.data
            : 'Upload Failed';
        showNotification(false, errorMessage);
      })
      .finally(() => setIsUploading(false));
  };

  const handleUploadSuccess = newDoc => {
    const updatedList = docsList;
    newDoc.lastUpdated = getFormattedDate(newDoc.lastUpdated);
    const doesDocAlreadyExist = updatedList.some(
      doc => doc.name === newDoc.name
    );

    if (!doesDocAlreadyExist) {
      updatedList.push(newDoc);
    } else {
      updateExistingDocNameInList(updatedList, newDoc);
    }
    setDocsList(updatedList);
    showNotification(
      true,
      getUploadSuccessMessage(doesDocAlreadyExist, newDoc.name)
    );
  };

  const updateExistingDocNameInList = (updatedList, newDoc) => {
    const matchingIndex = docsList.findIndex(doc => doc.name === newDoc.name);
    updatedList[matchingIndex] = newDoc;
  };

  const getUploadSuccessMessage = (doesDocExist, docName) => {
    const updateMessage = doesDocExist
      ? `${docName} Updated`
      : 'Upload Successful';
    return updateMessage;
  };

  const NoDocumentsOnFileMessage = () => (
    <p className={`${styles.noDocsMessage} is-size-5`}>
      There are no supplemental documents on file for this candidate
    </p>
  );

  const handleDelete = (profileId, docName) => {
    setDocBeingDeleted(docName);
    deleteDocument(profileId, docName)
      .then(() => {
        handleDeleteSuccess(docName);
      })
      .catch(() => {
        showNotification(false, `Failed To Delete ${docName}`);
      })
      .finally(() => {
        setDocBeingDeleted(null);
      });
  };

  const handleDeleteSuccess = docName => {
    const updated = docsList.filter(doc => doc.name !== docName);
    setDocsList(updated);
    showNotification(true, `${docName} Deleted`);
  };

  const showNotification = (isSuccess, message) => {
    setPopNotification(null);
    setPopNotification({ isSuccess: isSuccess, message: message });
  };

  const getTableColumns = () => {
    return [
      { heading: 'File Name', dataKey: 'name', isLink: false },
      { heading: 'Last Updated', dataKey: 'lastUpdated', isLink: false },
      {
        dataKey: 'name',
        dataRenderComponent: (
          <TableViewDocButton
            docTypeId={documentTypeIds.SUPPLEMENTAL_DOCUMENT}
            profileId={parseInt(candidateProfileId)}
            buttonText={`View`}
          />
        ),
      },
      {
        dataKey: 'name',
        dataRenderComponent: (
          <TableDeleteDocButton
            handleDelete={handleDelete}
            profileId={candidateProfileId}
            docBeingDeleted={docBeingDeleted}
          />
        ),
      },
    ];
  };

  return (
    <>
      <h5 className={`is-subtitle is-size-3`}>Supplemental Documents</h5>
      {hasFetchError ? (
        <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_FETCH} />
      ) : (
        <>
          <Table
            columns={getTableColumns()}
            isLoading={isLoading}
            rowData={docsList}
            tableClass={`is-fullwidth`}
            hasFetchError={false}
          />

          {!isLoading && docsList.length === 0 && <NoDocumentsOnFileMessage />}

          <div className={`file is-info level-right ${styles.tableButton} `}>
            <FileUploadButton
              handleFileUpload={handleFileUpload}
              acceptedFileTypes="application/pdf"
              isUploading={isUploading}
            />
          </div>
        </>
      )}
      {popNotification && (
        <PopupNotification
          message={popNotification.message}
          onClose={() => setPopNotification(null)}
          duration="short"
          notificationStyleClass={`${
            popNotification.isSuccess ? 'is-success' : 'is-danger'
          }`}
        />
      )}
    </>
  );
};

export default SupplementalDocsSection;

import React, { useEffect, useState } from 'react';
import { useCandidateSubmittalDataApi } from '../../../../../services/candidate-submittal-data-service';
import { useDistanceApi } from '../../../../../services/distance-service';
import styles from './index.module.scss';
import { errorMessages } from '../../../../../constants/error-messages';
import { navigate } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from '../../../../../components/loading-spinner';
import ApiErrorMessage from '../../../../../components/error-messages/api-error-message';
import { Form, Formik } from 'formik';
import Textarea from '../../../../common/components/forms/formik-inputs/textarea';
import validationSchema from './validation-schema';
import MatchesTable from './matches-table';
import PopupNotification from '../../../../../components/popup-notification';
import Modal from '../../../../../components/modal';
import NameCard from '../create-submittals/name-card';
import SubmittalUploadStateModal from './submittal-upload-state-modal';
import SubmittalUploadStates from '../../../../../constants/submittal-uploads-states';

const CreateSubmittals = ({ profileId, location }) => {
  const {
    getCandidateWithJobSubmittals: getCandidateJobSubmittalsWithCandidate,
    uploadSubmittalsToBullhornOrDownloadSubmittalPdf,
  } = useCandidateSubmittalDataApi();

  const { getDistancesToJobs } = useDistanceApi();

  const [isLoadingSubmittals, setIsLoadingSubmittals] = useState(true);
  const [haveSubmissionDistancesLoaded, setHaveSubmissionDistancesLoaded] =
    useState(true);
  const [jobSubmittalOptions, setJobSubmittalOptions] = useState([]);
  const [candidate, setCandidate] = useState(null);
  const [errorFetchingSubmittals, setErrorFetchingSubmittals] = useState('');
  const [isCreatingSubmittalPdf, setIsCreatingSubmittalPdf] = useState(false);
  const [selectedSubmittalIds, setSelectedSubmittalIds] = useState([]);
  const [uploadToBullhornResponse, setUploadToBullhornResponse] =
    useState(null);
  const [toast, setToast] = useState(null);
  const [uploadState, setUploadState] = useState(SubmittalUploadStates.NONE);
  const [showCancelConfirmationModal, setShowCancelConfirmationModal] =
    useState(false);
  const isLoading =
    Object.keys(jobSubmittalOptions).length === 0 && !errorFetchingSubmittals;

  useEffect(() => {
    getCandidateJobSubmittalsWithCandidate(profileId)
      .then(candidateWithJobSubmissions => {
        setCandidate(candidateWithJobSubmissions.candidate);
        setJobSubmittalOptions(candidateWithJobSubmissions.jobSubmissions);
        if (candidateWithJobSubmissions.jobSubmissions.length) {
          loadDistanceToSubmissions(candidateWithJobSubmissions.jobSubmissions);
        }
      })
      .catch(() => {
        setErrorFetchingSubmittals(
          "There was an error fetching the candidate's job submittals."
        );
      })
      .finally(() => setIsLoadingSubmittals(false));
  }, []);

  useEffect(() => {
    setUploadState(getSubmittalUploadState(uploadToBullhornResponse));
  }, [isCreatingSubmittalPdf, uploadToBullhornResponse]);

  const loadDistanceToSubmissions = submissions => {
    const submissionIds = submissions.map(submission => submission.id);
    getDistancesToJobs(submissionIds)
      .then(distances => {
        const updatedSubmissions = [...submissions];
        distances.forEach(distanceData => {
          let match = updatedSubmissions.find(
            sub => sub.id === distanceData.submissionId
          );

          match.milesToFacility = distanceData.distanceInMiles;
          match.distanceData = distanceData;
        });

        setJobSubmittalOptions(updatedSubmissions);
      })
      .catch(err => {
        const message = `There was a problem fetching distances to facilities: ${err}`;
        setToast({
          isError: true,
          message,
          duration: 'long',
          showCloseIcon: true,
        });
      })
      .finally(() => setHaveSubmissionDistancesLoaded(false));
  };

  const onSubmit = values => {
    setIsCreatingSubmittalPdf(true);
    let filteredJobSubmissions = jobSubmittalOptions.filter(j =>
      selectedSubmittalIds.includes(j.id)
    );
    const selectedCredentialIds = location.state?.selectedCredentialIds ?? [];
    const selectedChecklistIds = location.state?.selectedChecklistIds ?? [];
    uploadSubmittalsToBullhornOrDownloadSubmittalPdf(
      values,
      filteredJobSubmissions,
      profileId,
      selectedChecklistIds,
      selectedCredentialIds
    )
      .then(({ data }) => {
        setUploadToBullhornResponse(data);
      })
      .catch(error => {
        setUploadToBullhornResponse(error.response.data);
      })
      .finally(() => setIsCreatingSubmittalPdf(false));
  };

  const getNameAndSpeciality = candidateData => {
    if (!candidateData) return [];
    let name = `${candidateData.firstName} ${candidateData.lastName}`;
    let initials = `${candidateData.firstName[0]}${candidateData.lastName[0]}`;
    let primarySpecialty = candidateData.primarySpecialty;
    return [name, initials, primarySpecialty];
  };
  const [name, initials, primarySpecialty] = getNameAndSpeciality(candidate);

  const handleResetForModalClose = () => {
    setUploadToBullhornResponse(null);
  };

  const getSubmittalUploadState = uploadToBullhornResp => {
    if (isCreatingSubmittalPdf) {
      return SubmittalUploadStates.IN_PROGRESS;
    } else if (uploadToBullhornResp?.isSuccessful) {
      return SubmittalUploadStates.SUCCESS;
    } else if (
      uploadToBullhornResp?.isSuccessful === false &&
      uploadToBullhornResp?.failedSubmissions?.length > 0
    ) {
      return SubmittalUploadStates.PARTIAL_ERROR;
    } else if (uploadToBullhornResp?.isSuccessful === false) {
      return SubmittalUploadStates.errMsg ?? SubmittalUploadStates.ERROR;
    } else {
      return SubmittalUploadStates.NONE;
    }
  };

  return (
    <div className={styles.contentContainer}>
      {isLoading ? (
        <LoadingSpinner isLoading={isLoading} />
      ) : errorFetchingSubmittals ? (
        <ApiErrorMessage errorMsg={errorMessages.UNABLE_TO_FETCH} />
      ) : (
        <>
          <div className={styles.navButtons}>
            <button
              className={`button is-primary is-outlined is-square ${styles.backButton}`}
              onClick={() => setShowCancelConfirmationModal(true)}
            >
              <span className="icon">
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>
            </button>
          </div>
          <div className={styles.headerSection}>
            <h1 className="title is-1 has-text-weight-bold has-text-info">
              Create Submittal Packet
            </h1>
            <NameCard
              name={name}
              initials={initials}
              primarySpecialty={primarySpecialty}
            />
            <h3 className={`title is-3 has-text-info`}>Active Matches</h3>
            <p className={`subtitle is-6 ${styles.subtitleText}`}>
              To generate submittal packets, select one or more matches.
            </p>
          </div>
          <Formik
            initialValues={{
              submittals: jobSubmittalOptions,
              recruiterComments: '',
            }}
            validationSchema={validationSchema(selectedSubmittalIds)}
            onSubmit={onSubmit}
          >
            {({ values, handleSubmit }) => (
              <Form onSubmit={handleSubmit}>
                <LoadingSpinner isLoading={isLoadingSubmittals} />

                <MatchesTable
                  values={values}
                  initialSubmittalOptions={jobSubmittalOptions}
                  selectedJobSubmittalIds={selectedSubmittalIds}
                  setSelectedJobSubmittalIds={setSelectedSubmittalIds}
                  haveSubmissionDistancesLoaded={haveSubmissionDistancesLoaded}
                />

                <div className={styles.textContainer}>
                  <Textarea
                    name="recruiterComments"
                    labelText="Candidate Highlights"
                    labelClass={`is-size-7 ${styles.coversheetFormLabel}`}
                    shouldCheckForCapsLock={true}
                  />
                </div>

                <div className={`${styles.buttonContainer}`}>
                  <button
                    type="submit"
                    className={`button is-info ${
                      isCreatingSubmittalPdf ? 'is-loading' : ''
                    }`}
                    title="Select at least one match to create packet(s)"
                    disabled={!selectedSubmittalIds.length}
                  >
                    Create
                  </button>
                  <button
                    type="button"
                    className="button has-background-grey-light"
                    onClick={() => setShowCancelConfirmationModal(true)}
                  >
                    Cancel
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}

      <SubmittalUploadStateModal
        uploadState={uploadState}
        failedUploadsObject={uploadToBullhornResponse}
        closeModal={() => handleResetForModalClose()}
      />

      <Modal
        onConfirm={() => navigate(-1)}
        onCancel={() => setShowCancelConfirmationModal(false)}
        isOpen={showCancelConfirmationModal}
        title={`Cancel submittal packet`}
        message={`Are you sure you want to cancel?`}
        confirmButtonText={`Yes, cancel`}
        messageModifierClass="is-info"
        confirmButtonModifierClass={'is-info'}
        messageTextModifierClass={styles.modalMessageText}
        showCancelIconButton={false}
        addDividingLine={true}
        actionButtonsBodyClass={styles.modalActionButtonsAugment}
        cancelButtonClass={`${styles.modalCancelButton} is-text`}
        cancelButtonText={'Close'}
      />
      {toast && toast.message && (
        <PopupNotification
          message={toast.message}
          onClose={() => {
            setToast(null);
          }}
          duration={toast.duration}
          notificationStyleClass={toast.isError ? 'is-danger' : 'is-success'}
          showCloseIcon={toast.showCloseIcon}
        />
      )}
    </div>
  );
};

export default CreateSubmittals;

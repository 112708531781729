import React, { useState } from 'react';
import PropTypes from 'prop-types';

const TabsArea = ({ children: childrenProp, activeIndex, handleTabClick }) => {
  const onTabClick = tabIndex =>
    handleTabClick === undefined
      ? setFallbackActiveIndex(tabIndex)
      : handleTabClick(tabIndex);

  const [fallbackActiveIndex, setFallbackActiveIndex] = useState(0);
  const children = React.Children.map(childrenProp, child => {
    return React.cloneElement(child, {
      activeIndex: activeIndex || fallbackActiveIndex,
      onTabClick: onTabClick,
    });
  });

  return children;
};

TabsArea.propTypes = {
  children: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleTabClick: PropTypes.func,
  activeIndex: PropTypes.number,
};

export default TabsArea;

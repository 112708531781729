import { useAuthenticatedRequest } from '../hooks/api-hooks';

export function useCandidatePiiApi() {
  const { post } = useAuthenticatedRequest();
  const CANDIDATE_PII_CONTROLLER_BASEPATH = '/api/candidate-pii';

  return {
    forceWriteSsnToCrm: function (candidateProfileId) {
      const endpoint = `${CANDIDATE_PII_CONTROLLER_BASEPATH}/${candidateProfileId}/force-ssn`;
      return post(endpoint);
    },
    forceWriteDobToCrm: function (candidateProfileId) {
      const endpoint = `${CANDIDATE_PII_CONTROLLER_BASEPATH}/${candidateProfileId}/force-dob`;
      return post(endpoint);
    },
  };
}

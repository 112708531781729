import React from 'react';
import { Tooltip } from '@mui/material';
import { TooltipIcon as IUITooltipIcon } from '@ingenovis/iui';
import styled from '@emotion/styled';
import { Link } from 'gatsby';

const TooltipIconWrapper = styled.div`
  margin-bottom: 4px;
  display: inline-flex;
  vertical-align: middle;
`;

const muiTooltipProps = {
  tooltip: {
    sx: {
      fontWeight: '550',
      top: '-8px',
      display: 'flex',
      alignItems: 'center',
      borderRadius: '4px',
      padding: '8px',
    },
  },
};

const CandidateAppStatus = ({ rowData, dataKey }) => {
  return (
    <Link to={rowData.candidateDetailsLinkPath}>
      {rowData[dataKey]}
      {!rowData.isLinked && (
        <Tooltip
          arrow
          leaveDelay={100}
          placement="bottom"
          title="Candidates are not linked until they complete their skills checklist"
          componentsProps={muiTooltipProps}
        >
          <TooltipIconWrapper>
            <IUITooltipIcon dimensions={24} />
          </TooltipIconWrapper>
        </Tooltip>
      )}
    </Link>
  );
};

export default CandidateAppStatus;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAdminApi } from '../../../../services/admin-service';
import PopupNotification from '../../../../components/popup-notification';
import { httpResponses } from '../../../../constants/http-responses';

const GeneratePdfsButton = ({ profileId }) => {
  const { generateProfilePdf } = useAdminApi();

  const [hasApiError, setHasApiError] = useState(false);
  const [apiErrorMessage, setApiErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const createProfilePdf = profileId => {
    setIsLoading(true);
    setHasApiError(false);
    generateProfilePdf(profileId)
      .catch(err => {
        handleApiError(err);
      })
      .finally(() => {
        setIsLoading(false);
        setShowPopup(true);
      });
  };

  const handleApiError = err => {
    const isConflictResponse =
      err?.response?.status === httpResponses.CONFLICT_ERROR_CODE;
    const message = isConflictResponse
      ? err.response.data
      : 'Failed to create PDFs';
    setApiErrorMessage(message);
    setHasApiError(true);
  };

  return (
    <>
      <button
        className={`button is-info ${isLoading ? 'is-loading' : ''}`}
        disabled={isLoading}
        onClick={() => createProfilePdf(profileId)}
      >
        Refresh Profile PDF
      </button>
      {showPopup && (
        <PopupNotification
          message={hasApiError ? apiErrorMessage : 'PDF creation complete'}
          onClose={() => setShowPopup(false)}
          notificationStyleClass={hasApiError ? 'is-danger' : 'is-success'}
        />
      )}
    </>
  );
};

export default GeneratePdfsButton;

GeneratePdfsButton.propTypes = {
  profileId: PropTypes.number.isRequired,
};

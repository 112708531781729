import React from 'react';

const BullhornProfileLink = ({ rowData, dataKey }) => {
  const id = rowData[dataKey];
  return (
    <a
      title="Open in Bullhorn"
      href={`https://bullhornstaffing.com/BullhornSTAFFING/OpenWindow.cfm?Entity=Candidate&id=${id}&view=Overview`}
      target="_blank"
      rel="noreferrer"
    >
      {rowData[dataKey]}
    </a>
  );
};

export default BullhornProfileLink;

import React from 'react';
import styles from './index.module.scss';
import PropTypes from 'prop-types';

const Datafield = ({ heading, text, defaultText }) => {
  const renderText = text || defaultText;

  return (
    <div className={styles.dataField}>
      <h6 className={`is-size-5 has-text-weight-semibold`}>{heading}</h6>
      <p className={`is-size-6`}>{renderText}</p>
    </div>
  );
};

Datafield.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string,
  defaultText: PropTypes.string,
};

Datafield.defaultProps = {
  text: '',
  defaultText: 'N/A',
};

export default Datafield;
